import React from "react";
import Layout from "../components/layout";
import MetaTags from "react-meta-tags";
import { JsonLd } from 'react-schemaorg';

const Rights = function () {
  return (
    <Layout>
      <JsonLd
        item={{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          "name": "MSD Italia",
          "url": "https://www.testacollo.it/",
          "sameAs": [
            "https://www.linkedin.com/showcase/msd-salute-1/",
            "https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA",
            "https://www.instagram.com/msd_salute/",
            "https://twitter.com/msdsalute",
            "https://www.facebook.com/msdsalute",
            "https://www.msd-italia.it/"
          ],
          "logo": "https://www.testacollo.it/img/headers/logos-header.jpg",
          "image": "https://www.testacollo.it/img/home/home1.jpg",
          "description": "Salute, sistema assistenziale, lavoro e sistema previdenziale. Supporto durante e dopo il percorso terapeutico. I diritti del paziente oncologico",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Vitorchiano 151",
            "addressRegion": "RM",
            "postalCode": " 00189",
            "addressCountry": "Italy"
          },
          "@graph": [
            {
              "@type": "MedicalGuidelineRecommendation",
              "evidenceLevel": "https://schema.org/EvidenceLevelA",
              "evidenceOrigin": "multiple randomized clinical trials",
              "guidelineDate": "2020-12-01T20:00",
              "recommendationStrength": "Strong recommendation",
              "guidelineSubject": [
                {
                  "@type": "MedicalCondition",
                  "name": "head and neck cancer"
                }
              ]
            }
          ]
        }}></JsonLd>
      <MetaTags>
        <title>
          Tumore Testa Collo | Diritti del Paziente Oncologico
        </title>
        <meta name="description" content="Salute, sistema assistenziale, lavoro e sistema previdenziale. Supporto durante e dopo il percorso terapeutico. I diritti del paziente oncologico"></meta>
        <link rel="canonical" href="https://www.testacollo.it/diritti-paziente-oncologico/"></link>
      </MetaTags>
      <div
        className="img-header inside-page"
        style={{ backgroundImage: `url("img/headers/diritti.jpg")` }}
      ></div>
      <section className="page-section section">
        <h1>Diritti Paziente Oncologico</h1>

        <div className="feature-white-square">
          <h2 className="text-green">I diritti del paziente oncologico</h2>
          <div className="text-gray">
            <p>
              Il malato di tumore e i suoi famigliari hanno il diritto di
              beneficiare di una serie di agevolazioni e strumenti di supporto
              durante e dopo la terapia.
            </p>
            <p>
              <b>
                I diritti del paziente oncologico riguardano l’ambito della
                salute, il sistema assistenziale, il lavoro e il sistema
                previdenziale,
              </b>{" "}
              ma garantiscono al malato di tumore e alla famiglia anche il
              supporto in tema di nutrizione e il supporto psicologico necessari
              per affrontare la malattia e il percorso terapeutico.
            </p>
            <p>Diritto al supporto nutrizionale</p>
            <p>
              Il decalogo, messo a punto dalla{" "}
              <b>
                Federazione Italiana delle Associazioni di Volontariato in
                Oncologia{" "}
              </b>
              (FAVO), dall’<b>Associazione Italiana di Oncologia Medica</b>{" "}
              (AIOM) e dalla <b>Società Italiana di Nutrizione e Metabolismo</b>{" "}
              (SINPE), offre ai malati oncologici e i loro famigliari supporto
              in tema di nutrizione e corretta alimentazione durante e dopo il
              percorso terapeutico.
            </p>
          </div>

          <div className="my-2">
            <img src="/img/rights/MSD-24.png" alt="" />
          </div>

          <h2 className="text-green">
            La carta dei diritti del paziente oncologico
          </h2>

          <p>
          <ol className="text-gray my-2">
            <li>
              <p className="pl-1">
                Diritto alla corretta informazione e al counseling nutrizionale
              </p>
            </li>
            <li>
              <p className="pl-1">
                Diritto allo screening e alla valutazione dello stato
                nutrizionale
              </p>
            </li>
            <li>
              <p className="pl-1">Diritto alle prescrizioni nutrizionali</p>
            </li>
            <li>
              <p className="pl-1">
                Diritto all’accesso all’integrazione nutrizionale orale
              </p>
            </li>
            <li>
              <p className="pl-1">
                Diritto a ricevere una nutrizione artificiale appropriata e
                tempestiva
              </p>
            </li>
            <li>
              <p className="pl-1">
                Diritto a ricevere una nutrizione artificiale domiciliare
                appropriata e sicura
              </p>
            </li>
            <li>
              <p className="pl-1">
                Diritto al monitoraggio del supporto nutrizionale
              </p>
            </li>
            <li>
              <p className="pl-1">
                Diritto alla cura del sovrappeso associato alle terapie
              </p>
            </li>
            <li>
              <p className="pl-1">Diritto al supporto psicologico</p>
            </li>
            <li>
              <p className="pl-1">
                Diritto a partecipare a studi clinici controllati in tema di
                nutrizione clinica
              </p>
            </li>
          </ol>
          </p>
          <h2 className="text-green">Diritto al supporto psicologico</h2>

          <p className="text-gray">
            Dagli studi emerge che tra i pazienti affetti da tumore:
          </p>

          <div className="row col-numbers my-2 flex-center">
            <div className="col-30">
              <h1>30%</h1>
              <p className="text-gray">Soffre di ansia</p>
              <p className="text-gray">significativa</p>
            </div>
            <div className="col-30">
              <h1>20-35%</h1>
              <p className="text-gray">Soffre</p>
              <p className="text-gray">di depressione</p>
            </div>
            <div className="col-30">
              <h1>75%</h1>

              <p className="text-gray">Soffre di distress</p>
              <p className="text-gray">psicologico, ovvero</p>
              <p className="text-gray">un’esperienza</p>
              <p className="text-gray">emozionale negativa</p>
            </div>
          </div>

          <p className="text-gray">
            Questi stati d’animo possono incidere sulla capacità di affrontare
            la malattia e avere conseguenze negative anche sull’efficacia del
            percorso terapeutico.<br></br>
            Lo shock che affrontano i pazienti alla comunicazione dello stato
            della malattia provoca una serie di sintomi, quali:
          </p>

          <div className="row my-2">
            <ul className="text-gray col-50 gray-bullets">
              <li>Incertezza</li>
              <li>Frustrazione e sensi di colpa</li>
              <li>Trasformazioni fisiche</li>
              <li>Angoscia</li>
              <li>Modificazione dello stile di vita</li>
            </ul>

            <ul className="text-gray col-50 gray-bullets">
              <li>Caduta di autostima</li>
              <li>Ostilità e aggressività</li>
              <li>Senso di ingiustizia e di invidia</li>
              <li>Senso di impotenza</li>
              <li>Depressione</li>
            </ul>
          </div>

          <p className="text-gray">
            A causa di questi risvolti psicologici le linee guida internazionali
            e le linee guida AIOM, (Associazione Italiana di Oncologia Medica)
            raccomandano una serie di{" "}
            <b>
              interventi di sostegno psicologico a favore del paziente e dei
              suoi familiari
            </b>
            , per gestire e ridurre l’impatto psicologico della malattia e delle
            relative cure.<br></br>
            Lo <b>psico-oncologo</b> ha il compito di valutare non solo la
            malattia e lo stato di condizioni generali del paziente, ma anche il
            suo contesto socio-familiare, lavorativo, culturale ed economico
            così da poter proporre al paziente il percorso più idoneo per
            affrontare al meglio le ripercussioni psicologiche della patologia e
            delle successive terapie.
          </p>
        </div>
      </section>
      <section className="page-section-gray section">
        <h2>Bibliografia</h2>
        <ul className="esm-font gray-bullets">
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.aimac.it/libretti-tumore/diritti-malato-cancro"
              target="_blank"
            >
              https://www.aimac.it/libretti-tumore/diritti-malato-cancro
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.favo.it/pubblicazioni/carta-diritti-nutrizione.html"
              target="_blank"
            >
              https://www.favo.it/pubblicazioni/carta-diritti-nutrizione.html
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.fondazioneaiom.it/alimentazione-nel-paziente-oncologico/"
              target="_blank"
            >
              https://www.fondazioneaiom.it/alimentazione-nel-paziente-oncologico/
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="http://www.asmn.re.it/allegati/Opuscoli%20Aziendali/DirittiMalati%20oncologiciFebbraio2016.pdf"
              target="_blank"
            >
              http://www.asmn.re.it/allegati/Opuscoli%20Aziendali/DirittiMalati%20oncologiciFebbraio2016.pdf
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.infamigliaallimprovviso.it/sospetto-diagnostico/diritti-dei-pazienti/"
              target="_blank"
            >
              https://www.infamigliaallimprovviso.it/sospetto-diagnostico/diritti-dei-pazienti/
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.infamigliaallimprovviso.it/sospetto-diagnostico/supporto-psicologico/"
              target="_blank"
            >
              https://www.infamigliaallimprovviso.it/sospetto-diagnostico/supporto-psicologico/
            </a>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default Rights;

import React, { useState } from "react";
import { patologyTabExam } from "../../../../objects/diagnose";


const Esami = function () {
  const [tabExam, settabExam] = useState("esame-obiettivo");
 
  return (
  <section>
    <h2>Esami</h2>

    <div className="row">
      <div className="col-60">
        <p>
          La diagnosi dei tumori della testa e del collo si basa sui
          seguenti esami:
        </p>
        <div className="my-2">
          {patologyTabExam.map((item) => (
            <button
              className={
                "btn-dot d-block " +
                (tabExam === item.id ? "active " : "deactivated ") +
                item.className
              }
              onClick={() => settabExam(item.id)}
              key={item.id}
            >
              {item.title}
            </button>
          ))}
        </div>
      </div>

      <div className="col-40">
        <img className="img-scale" src="/img/patology/tabs/esami.png" alt="" />
      </div>
    </div>

    <div className={tabExam === "esame-obiettivo" ? "d-block" : "d-none"}>
      <p>
        Include <b>l’ispezione del cavo orale, del naso e del collo</b> da
        parte dello specialista; inoltre viene effettuata la palpazione di
        labbra, gengive, guance e collo per rilevare l’eventuale presenza di
        noduli.
      </p>
    </div>

    <div className={tabExam === "endoscopia" ? "d-block" : "d-none"}>
      <p>
        Procedura che permette di <b>esplorare le aree corporee sospette</b>{" "}
        non visibili ad occhio nudo o attraverso la palpazione. Si tratta di
        uno strumento sottile a forma di tubo dotato di illuminazione ad una
        estremità. Nella maggior perte dei casi, l'endoscopia può essere
        eseguita in ambulatorio, senza nessun tipo di anestesia.
      </p>
    </div>

    <div className={tabExam === "esame-istologico" ? "d-block" : "d-none"}>
      <p>
        Consiste in <b>un’analisi di laboratorio del tessuto tumorale </b>
        per analizzare e studiare al microscopio il materiale prelevato con
        la biopsia. La biopsia può essere eseguita per via endoscopica o,
        quando possibile, prelevando un campione del tessuto dalla bocca.
      </p>
    </div>

    <div
      className={tabExam === "biomarcatori-tumorali" ? "d-block" : "d-none"}
    >
      <p>
        I biomarcatori sono{" "}
        <b>molecole biologiche che si possono rilevare nel sangue</b>{" "}
        (intero oppure in plasma o siero), nelle urine, nelle feci e in
        altri fluidi corporei o tessuti, con un approccio che può essere
        non-invasivo o derivato da tessuto (biopsia o imaging). Possono
        aiutare gli oncologi nella definizione della prognosi (biomarcatori
        prognostici) oppure nella previsione della risposta ad una specifica
        terapia (biomarcatori predittivi).
      </p>
      <p>
        Esiste una grande varietà di biomarcatori: proteine (enzimi o
        recettori), acidi nucleici (microRNA o altre catene RNA non
        codificanti), anticorpi e peptidi. Un biomarcatore può anche essere
        metabolomica. I marcatori genetici possono essere ereditari e
        rilevati quindi come modificazioni della sequenza del DNA germinale
        e isolati da sangue, saliva o cellule della bocca, oppure somatico e
        identificato quindi attraverso mutazioni del DNA nel tessuto
        tumorale.
      </p>
      <p>
        Negli ultimi anni e grazie all’avvento di terapie sempre più mirate,{" "}
        <b>
          il numero di biomarcatori predittivi è costantemente aumentato
        </b>
        , soprattutto per permettere l’accesso alle cure migliori per i
        pazienti più adatti. Questo concetto innovativo prende il nome di
        medicina personalizzata.
      </p>
      <p>
        Le moderne tecniche di determinazione dei biomarcatori, ormai
        largamente utilizzate nella comune pratica clinica, hanno
        ulteriormente favorito il concetto di medicina personalizzata
      </p>
      <p>
        Alcuni farmaci immunoterapici, infatti, sono stati legati fin da
        subito a specifici biomarcatori. È il caso del PD-L1, ad oggi sempre
        più riconosciuto come predittivo di risposta per la classe di
        immunoterapici diretti al PD-1 nel tumore del polmone, in quello del
        distretto testa-collo e nel carcinoma uroteliale.
      </p>
      <p>
        La valutazione di PD-L1 avviene mediante immunoistochimica sul
        tessuto prelevato, una tecnica ampiamente utilizzata nella diagnosi
        di diverse patologie.
      </p>
    </div>

    <div className={tabExam === "hpv" ? "d-block" : "d-none"}>
      <p>
        La valutazione dello <b>stadio di positività al papilloma virus </b>
        (HPV) ha un valore prognostico. L’espressione della proteina p16,
        determinata con metodica immunoistochimica su tessuto prelevato, è
        un marker surrogato di infezione da HPV e la sua valutazione deve
        essere determinata nei carcinomi dell’orofaringe.
      </p>
    </div>

    <div className={tabExam === "esami-radiologici" ? "d-block" : "d-none"}>
      <p>
        Esami quali <b>la risonanza magnetica</b> (RM), la{" "}
        <b>tomografia computerizzata </b>(TC) e{" "}
        <b>la tomografia ad emissioni di positroni </b>(PET) vengono
        eseguiti per stabilire le dimensioni e la forma del tumore e/o la
        risposta al trattamento effettuato.
      </p>
    </div>

    <div
      className={tabExam === "risonanza-magnetica" ? "d-block" : "d-none"}
    >
      <p>
        Solitamente scelta per tutti i tumori della testa e del collo,
        tranne che per i tumori che colpiscono le sotto-sedi laringea e
        ipofaringea;{" "}
        <b>
          consente di visualizzare in maniera più approfondita i tessuti
          molli
        </b>
        . La RM con mezzi di contrasto fornisce maggiori informazioni in
        merito alle caratteristiche intrinseche del tumore, sull’estensione
        della malattia e sull’infiltrazione muscolare.
      </p>
    </div>

    <div
      className={
        tabExam === "tomografia-computerizzata" ? "d-block" : "d-none"
      }
    >
      <p>
        Permette di verificare i tessuti molli, le strutture ossee, i
        linfonodi e i vasi sanguigni.{" "}
        <b>
          È utilizzata in caso di controindicazioni all’utilizzo della RM
        </b>
        ; inoltre consente di poter rilevare un eventuale interessamento
        osseo macroscopico.
      </p>
    </div>

    <div className={tabExam === "pet" ? "d-block" : "d-none"}>
      <p>
        È utile nel <b>rilevare metastasi a distanza</b> e nel follow-up
        post-trattamento. L’esame PET è sempre più frequentemente associato
        alla TC senza mezzo di contrasto (PET-TC), utilizzabile soprattutto
        nella valutazione della malatta metastatica e nell’individuazione di
        neoplasie a sede primitiva ignota.
      </p>
    </div>
  </section>
  )
};

export default Esami;
import React, { useState } from "react";
import Layout from "../components/layout";
import ReactSlidy from "react-slidy";
import "react-slidy/lib/index.scss";
import { slideHome, sliderComunicazione } from "../objects/index.js";
import MetaTags from "react-meta-tags";
import { JsonLd } from 'react-schemaorg';

const Home = function () {
  //slide home
  const createStylesHome = (isActive) => ({
    color: isActive ? "#ffffff" : "#bbc5c5",
  });
  const [actualSlideHome, setActualSlideHome] = useState(0);
  const updateSlideHome = ({ currentSlideHome }) => {
    setActualSlideHome(currentSlideHome);
  };

  //slide campaign
  const createStyles = (isActive) => ({
    color: isActive ? "#ffffff" : "#3d4f66",
  });
  const [actualSlide, setActualSlide] = useState(0);
  const updateSlide = ({ currentSlide }) => {
    setActualSlide(currentSlide);
  };

  return (
    <Layout>
      <JsonLd
        item={{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          name: "MSD Italia",
          url: "https://www.testacollo.it/",
          sameAs: [
            "https://www.linkedin.com/showcase/msd-salute-1/",
            "https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA",
            "https://www.instagram.com/msd_salute/",
            "https://twitter.com/msdsalute",
            "https://www.facebook.com/msdsalute",
            "https://www.msd-italia.it/",
          ],
          logo: "https://www.testacollo.it/img/headers/logos-header.jpg",
          image: "https://www.testacollo.it/img/home/home1.jpg",
          description:
            "I tumori che colpiscono l’area testa collo come cavo orale, lingua, laringe o cavità nasali sono poco conosciuti ma possono avere conseguenze serie",
          address: {
            "@type": "PostalAddress",
            streetAddress: "Via Vitorchiano 151",
            addressRegion: "RM",
            postalCode: " 00189",
            addressCountry: "Italy",
          },
          "@graph": [
            {
              "@type": "MedicalGuidelineRecommendation",
              evidenceLevel: "https://schema.org/EvidenceLevelA",
              evidenceOrigin: "multiple randomized clinical trials",
              guidelineDate: "2020-12-01T20:00",
              recommendationStrength: "Strong recommendation",
              guidelineSubject: [
                {
                  "@type": "MedicalCondition",
                  name: "head and neck cancer",
                },
              ],
            },
          ],
        }}
      ></JsonLd>
      <MetaTags>
        <title>Tumore Testa Collo | Fattori di Rischio e Prevenzione</title>
        <meta
          name="description"
          content="I tumori che colpiscono l’area testa collo come cavo orale, lingua, laringe o cavità nasali sono poco conosciuti ma possono avere conseguenze serie"
        ></meta>
        <link rel="canonical" href="https://www.testacollo.it/"></link>
      </MetaTags>

      <div className="img-header">
        <ReactSlidy slide={actualSlideHome} showArrows={false}>
          {slideHome.map((item) => (
            <div
              className="bg-slideshow"
              key={item.id}
              style={{ backgroundImage: `url(${item.src})` }}
            >
              <div className="float-text text-white">
                <h1>{item.title}</h1>
              </div>
            </div>
          ))}
        </ReactSlidy>

        <div className="dots d-flex bullets-over-slide">
          <div className="dots-wrapper full">
            {slideHome.map((_, index) => {
              return (
                <button
                  key={index}
                  className="dot"
                  style={createStylesHome(index === actualSlideHome)}
                  onClick={() => updateSlideHome({ currentSlideHome: index })}
                >
                  &bull;
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <section className="bg-green text-white lg-font section position-relative py-5 mob-text">
        <div className="ribbon position-absolute">
          <img src="/img/home/MSD-Fiocco_resized.png" alt="" />
        </div>
        <div className="flex-center px-6">
          <p>
            <b>
              I tumori che colpiscono l’area tra testa e collo, come il cavo
              orale, la lingua, la laringe o le cavita nasali, sono poco
              conosciuti ma possono avere conseguenze molto serie. Scopri i
              fattori di rischio e gli alleati della prevenzione: puoi fare la
              differenza per te e per chi ti è vicino.
            </b>
          </p>
        </div>
      </section>

      <div className="fluid">
        <img src="/img/home/statistic.jpg" width="100%" alt="" />
      </div>

      <section className="page-section-white section">
        <div className="row">
          <div className="col-50">
            <img
              src="/img/home/patologia-focus-01.jpg"
              className="img-scale"
              alt=""
            />
          </div>
          <div className="col-50">
            <h2>
              Patologia <br></br>e prevenzione
            </h2>

            <p className="pb-3">
              <b>I tumori della testa e del collo</b> sono un gruppo di
              neoplasie maligne che possono interessare il tratto aero-digestivo
              superiore (cavo orale, lingua, labbra, gola, laringe e organo
              della voce), le ghiandole salivari, le cavità nasali e i seni
              paranasali.
            </p>
            <a href="/patologia-prevenzione" className="mob-link">
              <button className="btn btn-primary">SCOPRI DI PIÙ</button>
            </a>
          </div>
        </div>
      </section>

      <section className="bg-green text-white lg-font section position-relative py-5 mob-text">
        <div className="row">
          <div className="col-50">
            <h2 className="mt-2">
              Disfagia<br></br>e Disfonia
            </h2>
            <div className="pl-1-desktop">
              <p>
                Disfagia e disfonia sono due dei disturbi più comuni associati
                ai Tumori Testa-Collo. Entrambi possono essere dei campanelli
                dall’allarme per quanto riguarda la possibile insorgenza della
                malattia.
              </p>
            </div>

            <a href="/DisfagiaDisfonia" className="mob-link">
              <button className="btn btn-white text-blue mt-2 mb-2">
                SCOPRI DI PIÙ
              </button>
            </a>
          </div>
          <div className="col-50 row">
            <div className="col-90 shadow bg-gray-light mr-2 mt--80 mb--100">
              <img
                src="/img/home/Home-FotoSezione.jpg"
                className="img-scale"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="page-section-white section">
        <div className="row">
          <div className="col-50">
            <img
              className="img-scale py-5 quiz-home-mobile"
              src="/img/home/quiz.png"
              alt=""
            />
          </div>
          <div className="col-50">
            <h2>
              Quiz<br></br>della prevenzione
            </h2>

            <p className="pb-2">
              Utile, facile, interattivo: <b>il quiz della prevenzione</b> sui
              tumori testa-collo non sostituisce i consulti specialistici, ma
              può aiutarti a individuare se nel tuo stile di vita, o in quello
              dei tuoi cari, sono presenti fattori di rischio.
            </p>
            <a href="/quiz-prevenzione" className="mob-link">
              <button className="btn btn-primary">FAI IL QUIZ</button>
            </a>
          </div>
        </div>
      </section>

      <section>
        <div className="row">
          <div className="col-50 bg-green-light text-white block-pad sm-font">
            <h2 className="text-white line">
              Diagnosi<br></br>e trattamenti
            </h2>
            <p className="pb-1">
              Questa tipologia di tumore colpisce una parte del corpo umano
              visibile, che può, quindi, compromettere l’immagine corporea e, di
              conseguenza, la qualità di vita delle persone.
            </p>
            <a href="/diagnosi" className="mob-link">
              <button className="btn btn-white text-green-light">
                SCOPRI DI PIÙ
              </button>
            </a>
          </div>
          <div className="col-50 bg-green text-white block-pad sm-font">
            <h2 className="text-white line">
              Diritti Paziente <br></br>Oncologico
            </h2>
            <p className="pb-3">
              Chi lotta contro un tumore non è mai solo. Agevolazioni e
              strumenti di supporto aiutano i pazienti e i familiari ad
              affrontare meglio la malattia.
            </p>
            <a href="/diritti-paziente-oncologico" className="mob-link">
              <button className="btn btn-white">SCOPRI DI PIÙ</button>
            </a>
          </div>
        </div>
      </section>

      <section className="page-section-white section">
        <div className="row">
          <div className="col-100">
            <h2>Percorso nutrizionale</h2>

            <p className="pb-3">
              Recentemente, uno dei fattori considerati determinati per lo
              sviluppo dei tumori della testa e del collo, è stato scoperto
              essere l’alimentazione.
            </p>
            <a href="/patologia-prevenzione" className="mob-link">
              <button className="btn btn-primary">SCOPRI DI PIÙ</button>
            </a>
          </div>
        </div>
      </section>

      <section className="page-section-blue section">
        <div className="row">
          <div className="col-50">
            <h2 className="mt-">
              Campagna<br></br>di comunicazione
            </h2>
            <div className="pl-1-desktop">
              <p>
                In Italia la prevenzione, la diagnosi e la cura dei tumori
                testa-collo sono argomenti ancora molto lontani dalla diffusione
                di massa. Questo porta a sottovalutare una patologia che può
                arrivare a stravolgere i connotati dell’area testa-collo.
              </p>
              <p>
                Per comunicarlo nel modo più semplice ed efficace, nasce la
                campagna{" "}
                <b>“Con i tumori di bocca, naso e gola non si scherza”.</b>
              </p>
            </div>
          </div>
          <div className="col-50 row">
            <div className="col-90 mt--90 mb-2 shadow bg-gray-light">
              <ReactSlidy
                doAfterSlide={updateSlide}
                slide={actualSlide}
                showArrows={false}
              >
                {sliderComunicazione.map((item, index) => (
                  <img alt={item.title} src={item.src} key={index} />
                ))}
              </ReactSlidy>
            </div>
            <div className="col-10">
              <div className="dots d-flex">
                <div className="dots-wrapper vertical">
                  {sliderComunicazione.map((_, index) => {
                    return (
                      <button
                        key={index}
                        className="dot"
                        style={createStyles(index === actualSlide)}
                        onClick={() => updateSlide({ currentSlide: index })}
                      >
                        &bull;
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="page-section-blue section">
        <h3>Partner di progetto</h3>

        <div className="d-flex flex-center">
          <div className="feature-white row vertical-center">
            <div className="col-30 ml-2">
              <img className="col-80" src="/img/campaign/aiocc.jpg" alt="" />
            </div>
            <div className="col-50 text-gray bold">
              <p>
                AIOCC è l’Associazione Italiana di Oncologia Cervico-Cefalica e
                partner ufficiale della campagna di sensibilizzazione sui tumori
                della testa e del collo.
              </p>
            </div>
            <div className="col-20">
              <a
                href="http://www.aiocc.it/"
                target="_blank"
                rel="noopener noreferrer"
                className="mob-link"
              >
                <button className="btn btn-primary">SITO WEB</button>
              </a>
            </div>
          </div>
        </div>

        <p className="mt-2">
          <b>
            L’AIOCC ha differenti compiti istituzionali, tra cui i principali
            sono:
          </b>
        </p>
        <ul className="white-bullets">
          <li>
            Creazione di gruppi di studio per specifici settori di ricerca
          </li>
          <li>Promozione e svolgimento di protocolli di ricerca e studi</li>
          <li>Organizzazione di congressi e corsi</li>
          <li>
            Preparazione teorico e pratica di medici ed operatori sanitari
          </li>
          <li>
            Creazione di gruppi di lavoro per l’emissione di linee guida in
            ambito di oncologia cervico-cefalica
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default Home;

import React from "react";
import Layout from "../components/layout";
import { patologyTab} from "../objects/diagnose.js";
import MetaTags from "react-meta-tags";
import { JsonLd } from 'react-schemaorg';
import { Outlet,NavLink  } from 'react-router-dom';


const Diagnose = function () {
  
  

  return (
    <Layout>
      <JsonLd
        item={{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          "name": "MSD Italia",
          "url": "https://www.testacollo.it/",
          "sameAs": [
            "https://www.linkedin.com/showcase/msd-salute-1/",
            "https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA",
            "https://www.instagram.com/msd_salute/",
            "https://twitter.com/msdsalute",
            "https://www.facebook.com/msdsalute",
            "https://www.msd-italia.it/"
          ],
          "logo": "https://www.testacollo.it/img/headers/logos-header.jpg",
          "image": "https://www.testacollo.it/img/home/home1.jpg",
          "description": "Riconoscere i sintomi, sapere a quali specialisti rivolgersi e a quali esami sottoporsi. Scopri tutto questo sul sito dedicato. Clicca qui!",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Vitorchiano 151",
            "addressRegion": "RM",
            "postalCode": " 00189",
            "addressCountry": "Italy"
          },
          "@graph": [
            {
              "@type": "MedicalGuidelineRecommendation",
              "evidenceLevel": "https://schema.org/EvidenceLevelA",
              "evidenceOrigin": "multiple randomized clinical trials",
              "guidelineDate": "2020-12-01T20:00",
              "recommendationStrength": "Strong recommendation",
              "guidelineSubject": [
                {
                  "@type": "MedicalCondition",
                  "name": "head and neck cancer"
                }
              ]
            }
          ]
        }}></JsonLd>
      <MetaTags>
        <title>Tumore Testa Collo | Diagnosi e Trattamenti</title>
        <meta name="description" content="Riconoscere i sintomi, sapere a quali specialisti rivolgersi e a quali esami sottoporsi. Scopri tutto questo sul sito dedicato. Clicca qui!"></meta>
        <link rel="canonical" href="https://www.testacollo.it/diagnosi/"></link>
      </MetaTags>
      <div
        className="img-header inside-page"
        style={{ backgroundImage: `url("img/headers/diagnosi.jpg")` }}
      ></div>
     
      <section className="page-section section mpb-0">
        <h1>Diagnosi e trattamenti</h1>

        <p>
          <b>I tumori della testa e del collo</b> interessano la maggior parte
          dei piccoli organi della testa e del collo, di estrema importanza in
          termini di funzionalità, ad esclusione del cervello, degli occhi e
          della tiroide. Questa tipologia di tumore colpisce una parte del corpo
          umano visibile, che può quindi compromettere l’immagine corporea e, di
          conseguenza, la qualità di vita delle persone.
 
        </p>

        <div className="tabsWrapper d-flex space-between">
          {patologyTab.map((item) => (
            <NavLink to={item.path} className="tab " activeclassname="active"  key={item.id} >
              <div className={item.class}>{item.title}</div>
              <img className="col-60" alt={item.title} src={item.img} />
              <span></span>
            </NavLink>
          ))}
        </div>
      </section>

      <section
        className={"page-section-white section "}>
        <Outlet />
        
      </section>

      
      
      
      <section className="page-section-gray section">
        <h2>Bibliografia</h2>

        <h3>Diagnosi</h3>
        <ul className="esm-font gray-bullets">
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.msdmanuals.cn/it-it/professionale/disturbi-di-orecchio,-naso-e-gola/tumori-della-testa-e-del-collo/panoramica-sui-tumori-della-
        testa-e-del-collo"
              target="_blank"
            >
              https://www.msdmanuals.cn/it-it/professionale/disturbi-di-orecchio,-naso-e-gola/tumori-della-testa-e-del-collo/panoramica-sui-tumori-della-testa-e-del-collo
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.airc.it/area-stampa/tumori-squamosi-testa-collo-individuato-un-meccanismo-alla-base-dellaggressivita-della-malattia"
              target="_blank"
            >
              https://www.airc.it/area-stampa/tumori-squamosi-testa-collo-individuato-un-meccanismo-alla-base-dellaggressivita-della-malattia
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.fondazioneveronesi.it/magazine/tools-della-salute/glossario-delle-malattie/tumori-della-testa-e-del-collo"
              target="_blank"
            >
              https://www.fondazioneveronesi.it/magazine/tools-della-salute/glossario-delle-malattie/tumori-della-testa-e-del-collo
            </a>
          </li>
          <li>http://www.dipopavia.it/PUB/news/doc/201885101051.pdf</li>
          <li>Head&Neck – American Cancer Society</li>
          <li>Tumori testa collo - Istituto Europeo di Oncologia</li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.aiom.it/wp-content/uploads/2019/10/2019_LG_AIOM_HN.pdf"
              target="_blank"
            >
              https://www.aiom.it/wp-content/uploads/2019/10/2019_LG_AIOM_HN.pdf
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.esmo.org/content/download/78581/1439842/file/ESMO-ACF-Tumori-della-Testa-e-del-Collo-una-Guida-per-il-Paziente.pdf"
              target="_blank"
            >
              https://www.esmo.org/content/download/78581/1439842/file/ESMO-ACF-Tumori-della-Testa-e-del-Collo-una-Guida-per-il-Paziente.pdf
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.humanitas-care.it/visite-ed-esami/rinofibrolaringoscopia/"
              target="_blank"
            >
              https://www.humanitas-care.it/visite-ed-esami/rinofibrolaringoscopia/
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.airc.it/cancro/affronta-la-malattia/come-affrontare-la-malattia/stadiazione#:~:text=La%20stadiazione%20%C3%A8%20un%20modo,molto%"
              target="_blank"
            >
              https://www.airc.it/cancro/affronta-la-malattia/come-affrontare-la-malattia/stadiazione#:~:text=La%20stadiazione%20%C3%A8%20un%20modo,molto%
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.humanitas.it/news/13965-rivoluzione-pet-in-diagnostica"
              target="_blank"
            >
              https://www.humanitas.it/news/13965-rivoluzione-pet-in-diagnostica
            </a>
          </li>
        </ul>
        <h3>Trattamenti</h3>
        <ul className="esm-font gray-bullets">
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.aiom.it/wp-content/uploads/2019/10/2019_LG_AIOM_HN.pdf"
              target="_blank"
            >
              https://www.aiom.it/wp-content/uploads/2019/10/2019_LG_AIOM_HN.pdf
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.esmo.org/content/download/78581/1439842/1/ESMO-ACF-Tumori-della-Testa-e-del-Collo-una-Guida-per-il-Paziente.pdf"
              target="_blank"
            >
             https://www.esmo.org/content/download/78581/1439842/1/ESMO-ACF-Tumori-della-Testa-e-del-Collo-una-Guida-per-il-Paziente.pdf
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.msdmanuals.com/it-it/professionale/disturbi-di-orecchio,-naso-e-gola/tumori-della-testa-e-del-collo/panoramica-sui-tumori-della-testa-e-del-collo"
              target="_blank"
            >
              https://www.msdmanuals.com/it-it/professionale/disturbi-di-orecchio,-naso-e-gola/tumori-della-testa-e-del-collo/panoramica-sui-tumori-della-testa-e-del-collo
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.fondazioneveronesi.it/magazine/tools-della-salute/glossario-delle-malattie/tumori-della-testa-e-del-collo"
              target="_blank"
            >
              https://www.fondazioneveronesi.it/magazine/tools-della-salute/glossario-delle-malattie/tumori-della-testa-e-del-collo
            </a>
          </li>
          <li>

          <a
              rel="noopener noreferrer"
              href="https://www.ieo.it/it/PER-I-PAZIENTI/Cure/Tumori-della-testa-e-del-collo/"
              target="_blank"
            >
              https://www.ieo.it/it/PER-I-PAZIENTI/Cure/Tumori-della-testa-e-del-collo/
            </a>
          </li>
          <li>

          <a
              rel="noopener noreferrer"
              href="https://www.cancer.org/cancer/oral-cavity-and-oropharyngeal-cancer/if-you-have-head-or-neck-cancer.html"
              target="_blank"
            >
              https://www.cancer.org/cancer/oral-cavity-and-oropharyngeal-cancer/if-you-have-head-or-neck-cancer.html
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.aimac.it/libretti-tumore/radioterapia/l-irradiazione-della-testa-e-del-collo"
              target="_blank"
            >
              https://www.aimac.it/libretti-tumore/radioterapia/l-irradiazione-della-testa-e-del-collo
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href=" https://www.aimac.it/libretti-tumore/radioterapia/consigli-pratici-radioterapia"
              target="_blank"
            >
https://www.aimac.it/libretti-tumore/radioterapia/consigli-pratici-radioterapia
            </a>
          </li>
         {/* 
         <li>
            <a
              rel="noopener noreferrer"
              href="https://www.cancer.org/cancer/oral-cavity-and-oropharyngeal-cancer/if-you-have-head-or-neck-cancer.html"
              target="_blank"
            >
             https://www.cancer.org/cancer/oral-cavity-and-oropharyngeal-cancer/if-you-have-head-or-neck-cancer.html
             </a>
          </li>
            */}
          
        </ul>
      </section>
    </Layout>
  );
};

export default Diagnose;

import React from "react";

const goUp = e => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

const Footer = () => (
<>
  <footer className="bg-gray row">
      <div className="col-20 border-right vertical-center d-flex flex-center">
        <i className="icon-msd-icon"></i>
      </div>
      <div className="col-20 d-flex border-right px-1">
        <div className="col-20 mob-w-auto">
          <i className="icon-location"></i>
        </div>
        <div className="col-80 footer-address">
          <p>Via Vitorchiano, 151,</p>
          <p>00189 Roma RM</p>
          <br></br>
          <p>P.IVA 00887261006</p>
          <p>MSD Italia</p>
        </div>
      </div>
      <div className="col-10 d-flex flex-wrapper border-right px-1 text-center icon-social">
        <a rel="noopener noreferrer" href="https://www.facebook.com/msdsalute" className="social-a" target="_blank">
          <i className="col-50 icon-facebook"></i>
        </a>
        <a rel="noopener noreferrer" href="https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA?view_as=subscriber" className="social-a" target="_blank">
          <i className="col-50 icon-youtube"></i>
        </a>
        <a rel="noopener noreferrer" href="https://www.instagram.com/msd_salute/" target="_blank">
          <i className="col-50 icon-instagram"></i>
        </a>
        <a rel="noopener noreferrer" href="https://www.linkedin.com/showcase/msd-salute-1/?viewAsMember=true" target="_blank">
          <i className="col-50 icon-linkedin"></i>
        </a>
      </div>
      <div className="col-50 px-1 footer-text pt-1">

        <p>"Il presente materiale non intende in alcuna maniera, né
        direttamente, né indirettamente, delineare o sostituirsi a
        percorsi terapeutici che rimangono esclusiva responsabilità del
        medico curante.</p>
        <p>Le indicazioni contenute in questa pubblicazione non possono
        sostituire la cura del medico, che è pertanto necessario
        consultare prima di intraprendere qualsiasi cambiamento dello
        stile di vita".</p>
       
      </div>
      <button
        className="btn-go-top shadow"
        onClick={goUp}
        ><i className="icon-arrow_up"></i></button>
  </footer>
  <div className="d-flex flex-center bg-gray pb-0">
    <p className="text-white copyright px-1">Copyright © 2023 MSD Italia Srl All rights reserved. P.IVA 00887261006
      
    </p>
  </div>
  <div className="d-flex flex-center bg-gray pb-0">
    
      <span>
        <a rel="noopener noreferrer" href="/privacy-policy" className="text-white copyright px-1" target="_blank">Privacy policy</a>
      </span>
      <span>
        <a rel="noopener noreferrer" href="/farmacovigilanza" className="text-white copyright px-1" target="_blank">Farmacovigilanza</a>
      </span>
      <span className="text-white copyright px-1">
    <span className="ot-sdk-show-settings cursor-pointer">Preferenze Cookie</span>
    </span>
  
  </div>

  <div className="d-flex flex-center bg-gray pb-0">
  
  <p className="text-white copyright">IT-KEY-00663-W-10-2025</p>



  </div>


  <div className="d-flex flex-center bg-gray pb-0">
  
  <a id="accebility-link" className="text-white copyright px-1" rel="noopener noreferrer" href="https://www.msd.com/our-commitment-to-accessibility-for-all/" target="_blank">Accessibilità</a>    
  <a id="accebility-icon"  href="https://www.essentialaccessibility.com/msd" rel="noopener noreferrer" target="_blank"><img className="logo aligncenter" alt="L'icona Web Accessibility serve a scaricare il software per tecnologia assistiva eSSENTIAL Accessibility per le persone con disabilità fisiche. E' un'iniziativa a supporto della comunità dei disabili che dimostra il nostro impegno a sostegno della Diversità, Inclusione e Responsabilità Sociale." src="/img/footer/accessibility.png"></img></a>
            
  </div> 
</>
);

export default Footer;

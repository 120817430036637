import React from "react";

const Sintomi = () => (
  <section>
  <h2>Sintomi</h2>
        <p>
          I sintomi di un tumore della testa e del collo possono essere di varia
          natura a seconda della localizzazione del tumore e sono{" "}
          <b>
            solitamente identificati dal medico di medicina generale o dal
            dentista
          </b>
          , che indirizzeranno il paziente ad una{" "}
          <b>
            visita specialista con l’otorinolaringoiatra o il chirurgo
            maxillo-facciale.
          </b>
        </p>

        <div className="feature w-98 mt-2 m-0">
          <p>
            <b>
              Sottoporre all’attenzione del medico eventuali lesioni alla bocca,
              anche se indolori o di ridotte dimensioni, come noduli, afte,
              ulcere, placche ed escrescenze varie
            </b>
          </p>
        </div>

        <div className="my-2">
          <img className ="img-scale" src="/img/patology/tabs/sintomi.png" alt="" />
        </div>

        <div className="row space-between">
          <div>
            <p className="text-green">
              Le sedi anatomiche da cui si possono originare i tumori della
              testa e del collo sono:
            </p>
            <ul>
              <li>Labbra</li>
              <li>Lingua</li>
              <li>Gola</li>
              <li>Bocca</li>
              <li>Cavità nasali</li>
              <li>Laringe ed organo della voce</li>
              <li>Ghiandole salivari</li>
              <li>Rinofaringe</li>
              <li>Cute del viso</li>
              <li>Seni paranasali</li>
            </ul>
          </div>
          <div>
            <p className="text-green">
              I primi sintomi che vengono identificati sono:
            </p>
            <ul>
              <li>Evidenza di una massa</li>
              <li>
                Presenza di un nodulo al <br></br>collo
              </li>
              <li>Difficoltà/dolore a deglutire</li>
              <li>Dolore alla lingua</li>
              <li>Dolore alla gola</li>
              <li>Raucedine persistente</li>
              <li>Disfonia</li>
              <li>Disfagia</li>
              <li>Perdita di sangue dal naso</li>
              <li>Naso chiuso da un lato</li>
              <li>Difficoltà ad inspirare aria</li>
              <li>Dispnea</li>
              <li>Ostruzione nasale</li>
              <li>Ulcere in bocca che non guariscono</li>
              <li>
                Macchie bianche o rosse in<br></br> bocca
              </li>
              <li>Tumefazioni nel collo</li>
            </ul>
          </div>
          <div>
            <p className="text-green">
              I sintomi successivi dipendono dalla sede del tumore e possono
              comprendere:
            </p>
            <ul>
              <li>Dolore</li>
              <li>Paralisi dei nervi</li>
              <li>Trisma</li>
              <li>Alitosi</li>
              <li>Parastesia</li>
            </ul>
          </div>
        </div>
        </section>

);

export default Sintomi;
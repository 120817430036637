import React from "react";

const TipologieCure = () => (

  <div>
  <div className="my-2">
    <img src="/img/patology/tabs/programmi.png" alt="" />
  </div>

  <p className="text-green my-2">
    <b>Chirurgia</b>
  </p>
  <p>
    L’intervento chirurgico ha lo scopo di rimuovere il tumore in maniera radicale. Si
    effettua in condizioni di anestesia generale e potrebbe
    comportare l’asportazione delle principali catene di drenaggio linfatico laterocervicali. La resezione deve avvenire in margini sani (non coinvolti dalla malattia) 
    cercando di conservare le strutture vicine il più possibile, per compromettere
    il meno possibile l’estetica e la funzionalità degli organi del
    distretto cervico-facciale. In caso di tessuto mancante o di
    lesioni potrebbe essere associato un intervento di chirurgia
    ricostruttiva, volta a ripristinare l’estetica e le funzioni dei
    tessuti asportati.
  </p>

  <p className="text-green my-2">
    <b>Radioterapia</b>
  </p>
  <p>
    Tra i possibili trattamenti del tumore testa-collo c’è la
    radioterapia, che consiste nell’impiego di radiazioni al fine di
    uccidere le cellule tumorali. Al paziente viene fatta indossare
    una maschera per sorreggere la testa durante il trattamento.
    Le radiazioni vengono prodotte da un macchinario per
    radioterapia e dirette sulla porzione della testa o del collo in
    cui si trova il tumore. La radioterapia può essere impiegata a scopo curativo (mono trattamento) oppure successivamente, associata o meno a farmaci chemioterapici, in seguito a chirurgia per 
    il completamento del trattamento delle malattie in stadio più avanzato. 
  </p>

  <p className="text-green my-2">
    <b>Chemioterapia</b>
  </p>
  <p>
    La chemioterapia ha lo scopo di uccidere le cellule tumorali.
    Nella maggior parte dei casi viene somministrata in una
    vena e agisce attraverso il circolo del sangue. I farmaci
    possono essere impiegati singolarmente o in combinazione. Usualmente i trattamenti chemioterapici sono riservati in caso di inoperabilità, recidive e malattie 
    diffuse ad organi distanti dalla sede primitiva. 
    In alcuni casi la chemioterapia viene effettuata durante la
    radioterapia, per aumentare l’efficacia delle radiazioni.
  </p>

  
  <p className="text-green my-2">
    <b>Immunoterapia</b>
  </p>
  <p>
    L’immunoterapia oncologica o immunoterapia è un
    approccio innovativo e tra i più promettenti nel campo della
    ricerca oncologica e nel trattamento di alcune forme di
    tumore. I farmaci di questa classe agiscono sfruttando
    l’attività del sistema immunitario per aggredire le cellule
    tumorali.
  </p>


  <p className="text-green my-2">
    <b>Terapia biologica</b>
  </p>
  <p>
    È un trattamento basato sull’uso terapeutico di un anticorpo
    monoclonale che arresta la crescita delle cellule. Nei tumori
    testa-collo è possibile utilizzare un anticorpo monoclonale
    diretto contro il recettore del fattore di crescita epidermico
    (EGFR). L’anticorpo contrasta l’EGFR presente sulla
    superficie di tutte le cellule. Dato che l’EGFR è presente in
    grandi quantità sulle cellule dei tumori della testa e del collo,
    legandosi ad esso il farmaco causa la morte delle cellule
    tumorali.
  </p>


</div>
);

export default TipologieCure;


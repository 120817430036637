import React from "react";

const TumoriDelleGhiandoleSalivari = () => (
  <section>

    <div>
      <p>
        Nel caso di <b>tumori alle ghiandole salivari</b> (parotide,
        sottomandibolare, sottolinguale) la diagnosi può essere eseguita
        con una <b>visita specialistica</b> e con uno{" "}
        <b>studio ecografico</b> mirato per poter eseguire una manovra
        di <b>agoaspirazione</b> del campione cellulare delle aree
        sospette. Possono poi essere eseguiti degli ulteriori
        accertamenti con risonanza magnetica.{" "}
      </p>

      <div className="feature-blue mt-2 sm-font">
        <p>
          Per avere la certezza della presenza di un tumore{" "}
          <b>
            bisogna necessariamente attendere i risultati della biopsia
          </b>
          . Solitamente una massa cervicale viene analizzata con{" "}
          <b>agoaspirato</b>. Le lesioni orali, invece, vengono di norma
          analizzate con una{" "}
          <b>biopsia incisionale o tramite citologia esfoliativa</b>. In
          caso di possibili tumori a livello di rinofaringe, orofaringe
          e laringe si esegue una <b>biopsia endoscopica</b>.
        </p>
      </div>
    </div>



  </section>
);

export default TumoriDelleGhiandoleSalivari;


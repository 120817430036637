import React from "react";



const ProgrammiTrattamento = () => (

  <div>
  <div className="my-2">
    <img src="/img/patology/tabs/chirurgia.png" alt="" />
  </div>

  <p className="text-green my-2">
    <b>Tumori del cavo orale</b>
  </p>
  <p>
    Questi tumori richiedono una cura prevalentemente chirurgica.
    La precocità della diagnosi determina la semplicità
    dell’intervento e la portata delle conseguenze in termini
    funzionali. Quando la malattia è ad uno stadio avanzato,
    possono essere necessarie asportazioni estese, ricostruzioni
    sofisticate e tempi di riabilitazione più lunghi. La chemioterapia
    e la radioterapia, quando effettuate, rappresentano solo un
    completamento del trattamento chirurgico o una terapia
    alternativa in caso di impossibilità di eseguire l’intervento.

  </p>

  <p className="text-green my-2">
    <b>Tumori della laringe</b>
  </p>
  <p>
    Il trattamento dei tumori laringei dipende dallo stadio e dalla
    localizzazione della massa. Nella fase iniziale, potrebbe
    essere sufficiente un intervento di microchirurgia laser, mentre
    negli stadi avanzati il trattamento è spesso multimodale. Un
    esempio di terapia multimodale del tumore della laringe è una
    combinazione di chirurgia conservativa e radioterapia o
    chemioterapia.
  </p>

  <p className="text-green my-2">
    <b>Tumori della rinofaringe</b>
  </p>
  <p>
    Nelle fasi iniziali, il tumore può essere trattato con radioterapia
    esclusiva, mentre nelle fasi più avanzate la cura standard
    prevede un trattamento di chemioradioterapia; il ruolo della
    chirurgia è marginale e limitato ad alcuni casi di recidive e ai
    linfonodi non guariti sul collo.
  </p>

  <p className="text-green my-2">
    <b>Tumori dell’orofaringe</b>
  </p>
  <p>
    Il trattamento dipende dallo stadio e dalla sede di insorgenza
    del tumore. Generalmente, anche in questo caso, nei primi
    stadi si opta per un trattamento unimodale, come la
    radioterapia esclusiva o la chirurgia mini invasiva robotica o microscopica con laser, mentre
    negli stadi più avanzati si preferisce un approccio
    multimodale, con trattamenti di chemioradioterapia o chirurgia,
    seguiti da radioterapia o chemioradioterapia.
  </p>


  <p className="text-green my-2">
    <b>Tumori dell’ipofaringe</b>
  </p>
  <p>
    I fattori decisivi per la scelta del trattamento ideale sono lo
    stadio, la sede e le condizioni di salute del soggetto. Ad uno
    stadio iniziale, i tumori dell’ipofaringe possono essere trattati
    con metodi conservativi, come la radioterapia o la chirurgia
    mininvasiva. Con l’avanzare della malattia, si deve fare ricorso
    ad una combinazione di radioterapia e chemioterapia, oppure
    ad interventi chirurgici più estesi.
  </p>


  <p className="text-green my-2">
    <b>Tumori delle ghiandole salivari</b>
  </p>
  <p>
    La chirurgia, che rappresenta il trattamento più indicato,
    consente la rimozione del tumore, di alcuni tessuti circostanti
    e, laddove necessario, dei linfonodi laterocervicali. La
    presenza di strutture nervose nobili come il nervo facciale
    rende l’intervento particolarmente delicato. Tuttavia, in caso di
    danno, questo può essere ricostruito.
  </p>


  <p className="text-green my-2">
    <b>Tumori della cute e del volto</b>
  </p>
  <p>
    Il trattamento dei tumori del viso richiede un approccio
    combinato tra chirurgia curativa e chirurgia ricostruttiva, per
    salvaguardare le funzioni essenziali, quali la corretta
    respirazione, la masticazione e la protezione dell’occhio.
  </p>
</div>

);

export default ProgrammiTrattamento;


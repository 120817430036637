import React, { useState } from "react";
import Layout from "../components/layout";
import ReactSlidy from "react-slidy";
import "react-slidy/lib/index.scss";
import { patologySlideshow } from "../objects/patology.js";
import MetaTags from "react-meta-tags";
import { JsonLd } from 'react-schemaorg';

const Patology = function () {
  //slide
  const createStyles = (isActive) => ({
    color: isActive ? "#00857a" : "#cccccc",
  });
  const [actualSlide, setActualSlide] = useState(0);
  const updateSlide = ({ currentSlide }) => {
    setActualSlide(currentSlide);
  };
  const CustomArrowLeft = (props) => {
    return (
      <button
        {...props}
        className="custom-arrow arrow-left icon-arrow_left mob-left"
      ></button>
    );
  };
  const CustomArrowRight = (props) => {
    return (
      <button
        {...props}
        className="custom-arrow arrow-right icon-arrow_right mob-right"
      ></button>
    );
  };

  const [modalActive, setmodalActive] = useState(false);

  return (
    <Layout>
      <JsonLd
        item={{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          "name": "MSD Italia",
          "url": "https://www.testacollo.it/",
          "sameAs": [
            "https://www.linkedin.com/showcase/msd-salute-1/",
            "https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA",
            "https://www.instagram.com/msd_salute/",
            "https://twitter.com/msdsalute",
            "https://www.facebook.com/msdsalute",
            "https://www.msd-italia.it/"
          ],
          "logo": "https://www.testacollo.it/img/headers/logos-header.jpg",
          "image": "https://www.testacollo.it/img/home/home1.jpg",
          "description": "I tumori testa collo sono neoplasie maligne. Clicca qui per sapere quali siano le aree interessate, quali i fattori di rischio e come fare prevenzione.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Vitorchiano 151",
            "addressRegion": "RM",
            "postalCode": " 00189",
            "addressCountry": "Italy"
          },
          "@graph": [
            {
              "@type": "MedicalGuidelineRecommendation",
              "evidenceLevel": "https://schema.org/EvidenceLevelA",
              "evidenceOrigin": "multiple randomized clinical trials",
              "guidelineDate": "2020-12-01T20:00",
              "recommendationStrength": "Strong recommendation",
              "guidelineSubject": [
                {
                  "@type": "MedicalCondition",
                  "name": "head and neck cancer"
                }
              ]
            }
          ]
        }}></JsonLd>
      <MetaTags>
        <title>Tumore Testa Collo | Patologia e Prevenzione</title>
        <meta name="description" content="I tumori testa collo sono neoplasie maligne. Clicca qui per sapere quali siano le aree interessate, quali i fattori di rischio e come fare prevenzione."></meta>
      </MetaTags>
      <div
        className="img-header inside-page"
        style={{ backgroundImage: `url("img/headers/patologia.jpg")` }}
      ></div>
      <section className="page-section section">
        <h1>Patologia e prevenzione</h1>

        <h2>I tumori testa-collo</h2>

        {modalActive ? (
          <div
            className="img-modal cursor-pointer"
            onClick={() => setmodalActive(false)}
          >
            <img src="/img/patology/head-zoom.png" alt="" />
          </div>
        ) : (
          <>
            <p>
              <b>I tumori della testa e del collo</b> sono un gruppo di
              neoplasie maligne che interessano il tratto aero-digestivo
              superiore, le ghiandole salivari, il rinofaringe, le cavità nasali
              e i seni paranasali. In un'altra classificazione rientrano,
              invece, i tumori della tiroide, del cervello e degli occhi.
            </p>
            <p>
              Ogni anno in Italia, si diagnosticano circa 10.000 nuovi casi di
              tumore della testa e del collo. La malattia è{" "}
              <b>3 volte più frequente al nord</b> e il rischio, che aumenta con
              l’età, è maggiore negli uomini rispetto alle donne. La patologia
              può compromettere sensibilmente l‘aspetto fisico, con un forte
              impatto sulla qualità della vita del paziente.
            </p>
            <p>Le aree più interessate sono:</p>

            <div className="row">
              <div className="col-50">
                <h3 className="mb-0">CAVO ORALE</h3>

                <p>
                  Lingua, pavimento della bocca, mucosa buccale, palato duro e
                  creste alveolari
                </p>

                <h3 className="mb-0">LARINGE (o organo della voce)</h3>

                <p>
                  Nascono prevalentemente a livello delle corde vocali, ma
                  possono originare anche dalle strutture vicine
                </p>

                <h3 className="mb-0">OROFARINGE</h3>
                <p>
                  Pareti faringee, tonsille, palato molle e base della lingua
                </p>
              </div>
              <div
                className="col-50 text-center cursor-pointer"
                onClick={() => setmodalActive(true)}
              >
                <img
                  className="img-scale"
                  src="/img/patology/head.png"
                  alt=""
                />
              </div>
            </div>
            <div className="pt-1">
              <p>
                Possono anche essere definiti tumori del distretto
                cervico-cefalico e originano generalmente dalle cellule squamose
                che rivestono le parti interessate. Sono infatti detti{" "}
                <b>anche tumori testa-collo a cellule squamose.</b>
              </p>
            </div>
          </>
        )}
      </section>

      <section className="page-section-white section">
        <h2>Fattori di rischio e prevenzione</h2>

        <p>
          Sono stati identificati alcuni{" "}
          <b>fattori di rischio legati ai tumori della testa e del collo </b>che
          possono aumentare la probabilità di insorgenza della malattia.{" "}
          <b>
            Più del 70% di questi tumori sono legati al consumo di tabacco e di
            bevande alcoliche.
          </b>{" "}
          Forti consumatori di alcol e fumo presentano un rischio 40 volte
          superiore di sviluppare un carcinoma a cellule squamose del distretto
          testa-collo.
        </p>

        <div className="dots d-flex mob-dots">
          <div className="dots-wrapper">
            {patologySlideshow.map((_, index) => {
              return (
                <button
                  key={index}
                  className="dot"
                  style={createStyles(index === actualSlide)}
                  onClick={() => updateSlide({ currentSlide: index })}
                >
                  &bull;
                </button>
              );
            })}
          </div>
        </div>

        <ReactSlidy
          doAfterSlide={updateSlide}
          slide={actualSlide}
          ArrowLeft={CustomArrowLeft}
          ArrowRight={CustomArrowRight}
        >
          {patologySlideshow.map((item, index) => (
            <div className="two-col-slider row" key={index}>
              <div className="col-50">
                <img alt={item.title} src={item.img} className="img-scale" />
              </div>
              <div className="col-50 mt-3">
                <div className="title break-lines">{item.title}</div>
                <div className="text">{item.text}</div>
                <div
                  className={"feature " + (item.feature ? "d-block" : "d-none")}
                >
                  {item.feature}
                </div>
              </div>
            </div>
          ))}
        </ReactSlidy>
      </section>

      <section className="page-section-gray section">
        <h2>Bibliografia</h2>

        <h3>Fattori di rischio e prevenzione</h3>
        <ul className="esm-font gray-bullets">
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.esmo.org/content/download/78581/1439842/1/ESMO-ACF-Tumori-della-Testa-e-del-Collo-una-Guida-per-il-Paziente.pdf"
              target="_blank"
            >
              https://www.esmo.org/content/download/78581/1439842/1/ESMO-ACF-Tumori-della-Testa-e-del-Collo-una-Guida-per-il-Paziente.pdf
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.lastampa.it/salute/2017/12/13/news/l-igiene-orale-riduce-il-rischio-di-tumori-a-esofago-collo-e-testa-1.34082798"
              target="_blank"
            >
              https://www.lastampa.it/salute/2017/12/13/news/l-igiene-orale-riduce-il-rischio-di-tumori-a-esofago-collo-e-testa-1.34082798
            </a>
          </li>
          <li>LG AIOM 2019</li>
          <li>

            <a
              rel="noopener noreferrer"
              href="https://www.fondazioneveronesi.it/magazine/tools-della-salute/glossario-delle-malattie/tumori-della-testa-e-del-collo"
              target="_blank"
            >
              https://www.ieo.it/it/PER-I-PAZIENTI/Cure/Tumori-della-testa-e-del-collo/
            </a>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default Patology;

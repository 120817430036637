import React from "react";
import {  treatmentTabObj,} from "../../../../../objects/diagnose";
import { Outlet,NavLink  } from 'react-router-dom';


const Trattamenti = () => (
  
<section>
        <h2>Trattamenti</h2>
        <div className="row">
          <div className="col-40">
            {treatmentTabObj.map((item) => (
              <React.Fragment key={item.id}>
                <NavLink to={item.path} style={{ textDecoration: 'none' }} className={ "btn-dot d-block ordered "  } >
                  <i>{item.number}</i>
                  <div className={item.class}>{item.title}</div>
                </NavLink>
              </React.Fragment>
            ))}
          </div>
          <div className="col-60">
          <span></span>
          <Outlet />
          
            

          </div>
        </div>
      </section>
);

export default Trattamenti;
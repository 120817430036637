import React from "react";

const DopoTrattamento = () => (

  <div>
              <div className="my-2">
                <img src="/img/patology/tabs/recidive.png" alt="" />
              </div>
           

                <p className="text-green my-2">
                  <b>Follow up</b>
                </p>
                <p>
                Terminato il trattamento, gli specialisti consiglieranno al
                paziente di sottoporsi ad un programma di controlli periodici
                (follow up) con lo scopo di trattare gli effetti collaterali delle
                cure, evitare o diagnosticare precocemente le recidive e aiutare il paziente a tornare ad uno
                stile di vita normale.
                In questa fase, risultano particolarmente importanti le indagini
                di diagnostica per immagini e l’esame obiettivo, che
                permettono di individuare per tempo eventuali recidive e
                tumori di nuova insorgenza.
              </p>
              <p>
                In questa fase, risultano particolarmente importanti le indagini
                di diagnostica per immagini e l’esame obiettivo, che permettono
                di individuare per tempo eventuali recidive e tumori di nuova
                insorgenza.
              </p>


              <p className="text-green my-2">
                <b>Trattamento delle recidive</b>
              </p>
              <p>
                Il trattamento dei tumori che recidivano è complesso. Lo
                standard di trattamento è la chemioterapia, che aiuta ad
                alleviare i sintomi e a migliorare la qualità della vita. In caso di
                recidiva localizzata, si potrà intervenire con la chirurgia o con
                un’altra radioterapia. Nelle recidive locali trattate
                chirurgicamente vengono asportati, oltre al tumore residuo,
                tutti i piani cicatriziali.
              </p>

            </div>
);

export default DopoTrattamento;


import React from "react"
import { Outlet,NavLink  } from 'react-router-dom';
import {diagnoseTabExam} from '../../../../../objects/diagnose';






const Diagnosi = () => (
  
<section
        className={"page-section-white section "}>
        <h2>Diagnosi</h2>
        <p>
          La diagnosi per i tumori della testa e del collo è differente a
          seconda della tipologia di tumore.
        </p>

        <div className="my-2">
          <img className ="img-scale" src="/img/patology/tabs/diagnosi.png" alt="" />
        </div>

        

        <div className="row">
          <div className="col-30 pr-2">

            {diagnoseTabExam.map((item) => (
            <div className="row" key={item.id+"diagnosi"}>
              <NavLink to={item.path} className="btn w-100 " style={{ textDecoration: 'none' }} key={item.id} >
                {item.title}
              </NavLink>
              </div>
            ))}
         
          </div>
          <div className="col-70">
           
          <Outlet />
            

            

        
          </div>
        </div>
      </section>

);

export default Diagnosi;
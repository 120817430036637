export const quizSteps = [
    {
        title:'Qual è il tuo sesso?',
        id:1,
        img:'/img/quiz/MSD-26.png',
        text:'Il rischio di insorgenza dei tumori testa-collo aumenta con l’età ed è circa 4 volte più elevato negli uomini rispetto alle donne.',
        userAnswer:'',
        options:[
            {
                answer:'Uomo',
                changeResult:false
            },
            {
                answer:'Donna',
                changeResult:false
            },
        ],
    },
    {
        title:'Quanti anni hai?',
        id:2,
        img:'/img/quiz/MSD-27.png',
        text:'I tumori testa-collo si manifestano in particolare dopo i 40 anni, con una frequenza maggiore nei maschi tra i 50 e i 69 anni.',
        userAnswer:'',
        options:[
              {
                  answer:'18-39',
                  changeResult:false
              },
              {
                  answer:'40-59',
                  changeResult:false
              },
              {
                answer:'Over 60',
                changeResult:false
            },
        ],
    },
    {
        title:'Sei un fumatore?',
        id:3,
        img:'/img/quiz/MSD-28.png',
        text:'Il fumo è una delle cause principali dell’insorgenza di tumori testa-collo. Smettere è la scelta migliore per la tua salute.',
        userAnswer:'',
        options:[
              {
                  answer:'Sì',
                  changeResult:true,
              },
              {
                  answer:'No',
                  changeResult:false,
              },
              {
                answer:'Non più',
                changeResult:false,
            },
        ],
    },
    {
        title:'Bevi alcolici abitualmente?',
        id:4,
        img:'/img/quiz/MSD-29.png',
        text:'Insieme al fumo, l’alcol è la causa del 75% dei tumori testa-collo. Non esagerare con gli alcolici e bevi sempre con moderazione.',
        userAnswer:'',
        options:[
              {
                  answer:'Sì, ogni giorno',
                  changeResult:true,
              },
              {
                  answer:'Sì, solo ogni tanto',
                  changeResult:false,
              },
              {
                answer:'No o molto saltuariamente',
                changeResult:false,
            },
        ],
    },
    {
        title:'Sei positivo/a al papillomavirus (HPV) o lo sei stato in passato?',
        id:5,
        img:'/img/quiz/MSD-30.png',
        text:'Dopo alcol e fumo, il papillomavirus è la terza causa di insorgenza dei tumori testa-collo. Un rischio particolarmente elevato per le infezioni sostenute dai ceppi HPV-16 e HPV-18. Ricordati che il Papilloma Virus può essere trasmesso attraverso i rapporti sessuali.',
        userAnswer:'',
        options:[
              {
                  answer:'Sì, lo sono',
                  changeResult:true,
              },
              {
                  answer:'In passato ma adesso non più',
                  changeResult:false,
              },
              {
                answer:'No, non lo sono',
                changeResult:false,
            },
        ],
    },
    {
        title:'Curi correttamente la tua igiene orale?',
        id:6,
        img:'/img/quiz/MSD-31.png',
        text:'Una scarsa igiene orale può favorire l’insorgenza dei tumori testa-collo. Lavati i denti accuratamente dopo ciascun pasto spazzolando adeguatamente denti e lingua.',
        userAnswer:'',
        options:[
              {
                  answer:'Mi lavo i denti dopo ogni pasto',
                  changeResult:false,
              },
              {
                  answer:'Mi lavo i denti dopo i pasti principali o almeno 2 volte al giorno',
                  changeResult:false,
              },
              {
                answer:'A volte mi dimentico o non ho la possibilità di lavarmi i denti più volte al giorno',
                changeResult:false,
            },
        ],
    },
    {
        title:'Hai mai avuto mal di gola o raucedine per più di 3 settimane consecutive?',
        id:7,
        img:'/img/quiz/MSD-32.png',
        text:'Tantissime persone hanno sperimentato queste condizioni, nella maggior parte dei casi passeggere e superficiali, ma se noti che i sintomi descritti durano ininterrottamente per più di 3 settimane, contatta il tuo medico curante o uno specialista per un approfondimento.',
        userAnswer:'',
        options:[
              {
                  answer:'Sì, mi è capitato',
                  changeResult:true,
              },
              {
                  answer:'Ho avuto quei sintomi ma mai per 3 settimane di seguito',
                  changeResult:false,
              },
              {
                answer:'No',
                changeResult:false,
            },
        ],
    },
    {
        title:'Hai mai sofferto di sangue dal naso o naso chiuso da un lato per più di 3 settimane consecutive?',
        id:8,
        img:'/img/quiz/MSD-33.png',
        text:'L’epistassi (perdita di sangue dal naso) o la sensazione di avere il naso chiuso soltanto da una parte possono essere associati all’insorgenza dei tumori testa-collo. In presenza di questi sintomi per un periodo prolungato fallo presente al tuo medico curante o richiedi un consulto presso uno specialista.',
        userAnswer:'',
        options:[
              {
                  answer:'Mi è successo',
                  changeResult:true,
              },
              {
                  answer:'Mi è capitato, ma mai per 3 settimane consecutive',
                  changeResult:false,
              },
              {
                answer:'Non mi è mai successo',
                changeResult:false,
            },
        ],
    },
    {
        title:'Hai notato nel cavo orale lesioni come noduli, piccole ulcere, placche bianche o rosse o escrescenze, anche se ridotte o indolori, per più di 3 settimane consecutive?',
        id:9,
        img:'/img/quiz/MSD-34.png',
        text:'Alcune lesioni possono essere superficiali o passeggere, ma se entro circa 3 settimane dall’insorgenza non si risolvono spontaneamente, consulta uno specialista, potrebbe essere necessario individuarne la causa con più attenzione.',
        userAnswer:'',
        options:[
              {
                  answer:'Sì, mi è successo',
                  changeResult:true,
              },
              {
                  answer:'Mai per 3 settimane di seguito',
                  changeResult:false,
              },
              {
                answer:'Non ci ho mai fatto caso',
                changeResult:false,
            },
        ],
    },
    {
        title:'Mangi abitualmente frutta e verdura?',
        id:10,
        img:'/img/quiz/MSD-35.png',
        text:'Le abitudini alimentari sono importantissime per la prevenzione. Mangia spesso frutta e verdura, in particolare alimenti ricchi di carotenoidi come carote, pomodori, zucca, peperoni e albicocche. Cerca invece di non esagerare con il consumo di carne rossa.',
        userAnswer:'',
        options:[
              {
                  answer:'La mangio ogni giorno o quasi',
                  changeResult:false,
              },
              {
                  answer:'La mangio spesso ma non ogni giorno',
                  changeResult:false,
              },
              {
                answer:'La mangio saltuariamente o mai',
                changeResult:false,
            },
        ],
    },

  ]

export const emptyStateToDb =     {
    "question_1": null,
    "question_2": null,
    "question_3": null,
    "question_4": null,
    "question_5": null,
    "question_6": null,
    "question_7": null,
    "question_8": null,
    "question_9": null,
    "question_10": null
    }

  export default {quizSteps,emptyStateToDb}
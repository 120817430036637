import React from "react";
import Layout from "../components/layout";
import "react-slidy/lib/index.scss";
import MetaTags from "react-meta-tags";
import { JsonLd } from 'react-schemaorg';

const DisfagiaDisfonia = function () {


  return (
    <Layout>
      <JsonLd
        item={{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          "name": "MSD Italia",
          "url": "https://www.testacollo.it/",
          "sameAs": [
            "",
            "https://www.linkedin.com/showcase/msd-salute-1/",
            "https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA",
            "https://www.instagram.com/msd_salute/",
            "https://twitter.com/msdsalute",
            "https://www.facebook.com/msdsalute",
            "https://www.msd-italia.it/"
          ],
          "logo": "https://www.testacollo.it/img/headers/logos-header.jpg",
          "image": "https://www.testacollo.it/img/home/home1.jpg",
          "description": "Il tumore testa-collo si batte con la conoscenza, non con la paura. Informati e fai informare i tuoi cari.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Vitorchiano 151",
            "addressLocality": "Rome",
            "addressRegion": "RM",
            "postalCode": " 00189",
            "addressCountry": "Italy"
          },
          "@graph": [
            {
              "@type": "MedicalGuidelineRecommendation",
              "evidenceLevel": "https://schema.org/EvidenceLevelA",
              "evidenceOrigin": "multiple randomized clinical trials",
              "guidelineDate": "2020-12-01T20:00",
              "recommendationStrength": "Strong recommendation",
              "guidelineSubject": [
                {
                  "@type": "MedicalCondition",
                  "name": "head and neck cancer"
                },
                {
                  "@type": "TherapeuticProcedure",
                  "name": "radiotherapy and chemotherapy"
                },
                {
                  "@type": "MedicalContraindication",
                  "name": "alcohol and tobacco"

                }
              ]
            }
          ]
        }}>
      </JsonLd>
      <MetaTags>
        <title>Disfagia Disfonia</title>
        <meta name="description" content="Il tumore testa-collo si batte con la conoscenza, non con la paura. Informati e fai informare i tuoi cari."></meta>
        <link rel="canonical" href="https://www.testacollo.it/campagna-comunicazione/"></link>
      </MetaTags>
      <div
        className="img-header inside-page "
        style={{ backgroundImage: `url("/img/headers/DisfagiaDisfonia-Header1.jpg")` }}
      ></div>
      <section className="page-section section">
        <h1>
          Disfagia e Disfonia
        </h1>
          <div>
            <p>
              Disfagia e disfonia sono due dei disturbi più comuni associati ai Tumori Testa-Collo. Entrambi
              possono essere dei campanelli dall’allarme per quanto riguarda la possibile insorgenza della
              malattia oppure posso insorgere a seguito di interventi chirurgici o radioterapia radioterapia dovuti al trattamento di una patologia già diagnostica.
            </p>
          </div>
      
      </section>

      <section className="page-section-gray-on-white section">


        <div className="row">

          <div className="col-30">

            <h2 className="text-gray">La Disfagia</h2>
            <div className="text-gray">
              <p>
                La disfagia  è una sensazione
                di difficoltà durante la
                deglutizione sia di solidi che di liquidi  e rappresenta
                allo stesso tempo <strong>uno dei
                  principali sintomi dei tumori
                  Testa-Collo </strong>e uno degli effetti
                collaterali del trattamento
                radioterapico
              </p>
            </div>

          </div>
          <div className="col-70">
            <img
              src="/img/disfagiadisfonia/DisfagiaDisfonia-Img1.jpg"
              className="img-scale"
              alt=""
            />
          </div>

        </div>


        <div className="text-gray">
          <h2 className="text-gray">La disfagia come sintomo</h2>
          <p >
            La disfagia è classificata come orofaringea o esofagea, a seconda della sede interessata. In
            entrambi i casi, per i pazienti <strong>è fortemente raccomandata un’endoscopia del tratto superiore</strong>,
            che è estremamente importante per verificare la presenza di un’eventuale neoplasia. Può essere
            eseguito uno studio radiologico con  un pasto baritato se il paziente non è in grado di sottoporsi a un’endoscopia del
            tratto superiore, o se l’endoscopia del tratto superiore con biopsia non identifica una causa.

          </p>
        </div>
        <div className="text-gray">
          
          <h2 className="text-gray">La disfagia come effetto collaterale</h2>
          <p>
          <ul>
           La disfagia può verificarsi come conseguenza di interventi chirurgici e dopo trattamenti radioterapici. Tale complicazione può derivare da:
           
           <li>interventi del cavo orale, che possono causare la perdita di controllo della lingua e la conseguente manipolazione degli alimenti in fase di masticazione e deglutizione,</li>
           <li>interventi alla faringe e alla laringe, che possono causare difficoltà nel passaggio di liquidi e solidi dalla bocca all’esofago,</li>
           <li>interventi radioterapici possono causare dolore, ridotta salivazione (xerostomia), secchezza delle fauci e movimenti limitati.</li><br></br>
            </ul></p>
            </div>

            <div className="d-flex flex-center">
          <div className="feature-white row vertical-center">
            
            <div className="col-80 text-gray bold">
            <div className="feature">
            <p>
              <b>
              La radioterapia in particolare, tra tutti gli effetti collaterali che comporta, 
              può provocare l’insorgere della Disfagia come conseguenza del trattamento. 
              Informazioni più dettagliate sugli altri effetti collaterali che possono 
              derivare dalla radioterapia possono essere consultate nella sezione dedicata.
              </b>
            </p>
          </div>
            </div>
            <div className="col-20">
              <a
                href="/diagnosi/trattamenti/effetti-collaterali#radioterapia"
                
                rel="noopener noreferrer"
                className="mob-link"
              >
                <button className="btn btn-primary">SCOPRI DI PIU</button>
              </a>
            </div>
          </div>
        </div>
        
          <div className="text-gray" style={{ paddingTop: "2rem" }}>
            <h2 className="text-gray">I sintomi</h2>
            <p>
            I soggetti sottoposti a interventi terapeutici per i tumori testa-collo possono presentare uno o più
            sintomi legati alla disfagia. Tra questi:<br></br>
            • ritardo nella deglutizione<br></br>
            • tosse o soffocamento<br></br>
            • necessità di deglutire più volte<br></br>
            • voce gorgogliante dopo la deglutizione<br></br>
            • importante secchezza delle mucose<br></br>
            • dolore durante la deglutizione<br></br>
          </p>
        </div>


        <div className="feature-blue mt-2 ">
          <p>
            A chi rivolgersi?
            Il principale interlocutore per affrontare la disfagia, in seguito a trattamento chirurgico o radioterapico, è il Logopedista, che può fornire al paziente
            e al familiare importanti consigli per la gestione delle difficoltà. I contatti dei Logopedisti
            afferenti alla Federazione Logopedisti Italiani sono reperibili sul portale  <a
              href="http://www.fli.it./"
              target="_blank"
              rel="noopener noreferrer"
              className="mob-link"
            >www.fli.it.</a>
          </p>
        </div>

        <div className="text-gray mt-2">
          <h2 className="text-gray">I trattamenti</h2>
          <p>
            Se il paziente necessita di uno specifico trattamento, il Logopedista può proporre, in base al
            bisogno riabilitativo:<br></br>
            • esercizi della muscolatura<br></br>
            • impostazione di strategie di compenso<br></br>
            • ricerca di compensi posturali<br></br>
            • impostazione di tecniche di deglutizione<br></br>
            • modifiche di consistenza degli alimenti<br></br><br></br>

            Nei casi in cui la masticazione e la deglutizione diventino talmente difficili o dolorosi da impedire
            l’alimentazione per bocca, il Logopedista può consigliare di adottare  l’<a href="/percorso-nutrizionale" className="mob-link">
            alimentazione enterale</a> per
            un certo periodo di tempo con posizionamento di sondino naso – gastrico o, nei casi più complessi, di una PEG tramite gastrotomia.

          </p>
        </div>

      </section>
      <section className="page-section-deep-green section">
        <h1>
          Disfonia
        </h1>
        <p>
          Con Disfonia si intende una generale alterazione della voce, che può essere qualitativa e/o
          quantitativa, e di durata variabile fino ad essere addirittura permanente. Questo tipo di disturbo
          può intendersi come una difficoltà nel controllare l’intonazione, il timbro o il volume, oltre ad
          associarsi ad un certo grado di dolore o fastidio mentre si parla.

        </p>


        <h3>
          Le tipologie
        </h3>
        <p>
          La disfonia si può distinguere in Funzionale, se dovuta ad un uso scorretto o eccessivo della voce,
          oppure Organica, quando determinata da infiammazioni, malformazioni congenite, lesioni benigne
          o tumorali. Esiste anche un terzo tipo, chiamato Disfonia Spasmodica, caratterizzato da movimenti
          involontari o spasmi di uno o più muscoli della laringe, che rendono il parlare faticoso e strozzato o
          soffiato.
        </p>

        <h3>
          Le tipologie
        </h3>
        <p>

          Le tipologie
          La disfonia si può distinguere in Funzionale, se dovuta ad un uso scorretto o eccessivo della voce,
          oppure Organica, quando determinata da infiammazioni, malformazioni congenite, lesioni benigne
          o tumorali. Esiste anche un terzo tipo, chiamato Disfonia Spasmodica, caratterizzato da movimenti
          involontari o spasmi di uno o più muscoli della laringe, che rendono il parlare faticoso e strozzato o
          soffiato.
        </p>


        <div className="row">

          <div className="col-40">

            <h3 className="text-white">Fattori di rischio</h3>
            <div className="text-white">
              <p>
                Esistono numerose cause che concorrono alla
                perdita della voce, alcune delle quali sono
                semplici da trattare, mentre altre richiedono
                maggior cura e attenzione, specialmente se il
                disturbo si protrae nel tempo. Tra le principali
                cause della disfagia troviamo:<br></br>
                • Invecchiamento<br></br>
                • Abuso di Tabacco e Alcol<br></br>
                • Allergie<br></br>
                • Tumori<br></br>
                • Infezioni delle vie respiratorie<br></br>
                • Riflusso Gastroesofageo<br></br>
                • Malattie Neurologiche<br></br>
                • Malattie Tiroidee<br></br>
                • Disidratazione<br></br>
                • Stress psicologico<br></br>
                • Abuso della voce (disfonia funzionale)
              </p>
            </div>

          </div>
          <div className="col-60">
            <img
              src="/img/disfagiadisfonia/DisfagiaDisfonia-Img2.png"
              className="img-scale"
              alt=""
            />
          </div>

        </div>

        <h3>
          I sintomi
        </h3>
        <p>
          La disfonia viene comunemente identificata con la raucedine, che compare anche in assenza di
          infiammazioni o altre malattie, ma una voce si può considerare alterata anche nei casi in cui è
          totalmente assente (afonia), debole o affaticata (fonastenia), tremante o aspirata/sussurrata. In
          ciascuno di questi casi è fondamentale prestare attenzione alla sintomatologia che può indicare un
          disturbo più grave. I più comuni segnali d’allarme sono:<br></br>
          • Raucedine persistente (oltre 2/3 settimane)<br></br>
          • Dolore irradiato verso l’orecchio<br></br>
          • Gonfiore sul collo<br></br>
          • Difficoltà a respirare e/o deglutire associata a tosse o senso di soffocamento<br></br>
          • Tosse e mal di gola cronico (oltre 2 settimane)<br></br>
        </p>

        <div className="feature-blue mt-2 ">
          <p>
            A chi rivolgersi?
            Nel caso in cui si riscontrino i sintomi sopracitati, è opportuno consultare un Otorinolaringoiatra
            per un’accurata valutazione non strumentale e un esame obiettivo del distretto testa-collo. Gli
            esami che è possibile sostenere per una diagnosi accurata sono:<br></br>
            • Laringoscopia, per la valutazione delle corde vocali e il loro movimento durante la vibrazione.<br></br>
            • Laringostroboscopia, per ottenere una panoramica più accurata dei movimenti durante la
            vibrazione.<br></br>
            • Elettromiografia laringea, per misurare l’attività elettrica dei muscoli della laringe.
          </p>
        </div>

        <h3>I trattamenti </h3>
        <p>
          I primi accorgimenti per affrontare la Disfonia riguardano l’eliminazione dei fattori causali come lo
          stress, il fumo di sigaretta e l’alcol, mentre si raccomanda anche il riposo vocale per almeno due
          giorni. Nel caso ci sia necessità di un trattamento specifico, sono possibili 3 diversi approcci:<br></br><br></br>
          <strong> 1. Medico:</strong>  tramite la somministrazione di farmaci antinfiammatori, antibiotici o antiacidi, quando la
          Disfonia è causata rispettivamente da infiammazioni, infezioni batteriche o reflusso
          gastroesofageo.<br></br><br></br>
          <strong> 2. Chirurgico: </strong> con procedure di microlaringoscopia per l’asportazione di eventuali formazioni
          responsabili della Disfonia, o per l’esecuzione di procedure bioptiche in caso di sospetto di patologie maligne.<br></br><br></br>
          <strong> 3. Logopedico:</strong>  con tecniche specifiche mirate all’apprendimento dell’uso corretto della voce e ad
          evitarne l’abuso. In questo specifico caso il Logopedista svolge un ruolo centrale per il paziente.
        </p>

      </section>


      <section className="page-section-gray page-section-gray-biblio section">
        <h2>Bibliografia</h2>
        <ol className="esm-font gray-bullets">

          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.ospedaleniguarda.it/uploads/default/attachments/strutture/strutture_m/38/files/allegati/35/disfagia.pdf"
              target="_blank"
            >
             https://www.ospedaleniguarda.it/uploads/default/attachments/strutture/strutture_m/38/files/allegati/35/disfagia.pdf
            </a></li>
          
            <li>
            <a
              rel="noopener noreferrer"
              href="https://fli.it/wp-content/uploads/2017/02/GE2017_PIEMONTE_Disfagia-HN.pdf"
              target="_blank"
            >
             https://fli.it/wp-content/uploads/2017/02/GE2017_PIEMONTE_Disfagia-HN.pdf
            </a></li>

          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.msdmanuals.com/it-it/professionale/disturbi-gastrointestinali/disturbi-esofagei-e-della-deglutizione/disfagia"
              target="_blank"
            >
              https://www.msdmanuals.com/it-it/professionale/disturbi-gastrointestinali/disturbi-esofagei-e-della-deglutizione/disfagia
            </a></li>

          <li>
            <a
              rel="noopener noreferrer"
              href="https://www.my-personaltrainer.it/salute-benessere/disfonia.html"
              target="_blank"
            >
              https://www.my-personaltrainer.it/salute-benessere/disfonia.html
            </a></li>


          <li>
            <a
              rel="noopener noreferrer"
              href="
              https://www.humanitas.it/malattie/disfonia/#:~:text=Per%20disfonia%20si%20intende%20un,avere%20origine%20organica%20o%20funzionale./"
              target="_blank"
            >

              https://www.humanitas.it/malattie/disfonia/

            </a> </li>
        </ol>
      </section>
    </Layout>
  );
};




export default DisfagiaDisfonia;

import React from "react";



const Fattori = () => (

    <div>
    <div className="my-2">
    
      <img
        className ="img-scale"
        src="/img/patology/tabs/trattamenti.png"
        alt=""
      />

    </div>
    
    <p>
      <b>Il trattamento dei tumori della testa e del collo</b> combina
      terapie che agiscono a livello locale, come la radioterapia o la
      chirurgia, con terapie che trattano le cellule tumorali presenti in
      tutto il corpo, come la chemioterapia e la terapia biologica a
      bersaglio molecolare.
    </p>
    <p>
      Non esiste una cura migliore in assoluto. <b>Il programma di
        trattamento </b>viene stabilito in base ad alcuni fattori, quali:
    </p>
    <ul className="my-2">
      <li>La localizzazione del tumore</li>
      <li>Lo stadio del tumore</li>
      <li>Espressione di PD-L1</li>
      <li>La percentuale di successo del trattamento</li>
      <li>
        Gli effetti indesiderati, in particolare la possibilità di
        compromettere la capacità di parlare, respirare o mangiare
      </li>
      <li>Lo stato di salute generale</li>
      <li>L’età</li>
    </ul>
    <p className="text-green my-2">
      <b>Programma di trattamento per i tumori in stadio I e II</b>
    </p>

    <p>
      Quando il tumore si trova in stadio I o II, può essere trattato
      localmente. La scelta tra la chirurgia e la radioterapia è determinata in questi casi dalla sede di insorgenza. 
    </p>

    <p className="text-green my-2">
      <b>Programma di trattamento per i tumori in stadio III e IV</b>
    </p>

    <p>
      Tumori di stadio avanzato richiedono spesso un trattamento
      multimodale, che può prevedere una combinazione di chirurgia,
      radioterapia e chemioterapia.
      In linea generale, un tumore è considerato operabile quando vi
      è la certezza che non rimangano cellule tumorali residue o non
      venga compromessa la funzionalità degli organi colpiti.
      Il trattamento della malattia richiede la partecipazione sinergica
      di diversi specialisti, per elaborare quella che viene chiamata
      opinione multidisciplinare (o tumour board) e pianificare le cure.
      In concomitanza al trattamento, il paziente dovrà:
    </p>
    <p>In concomitanza al trattamento, il paziente dovrà:</p>

    <ul className="mt-2">
      <li>
        <p>
          Cessare le abitudini di vita considerate fattori di rischio,
          come il consumo di alcol e di tabacco. </p>
      </li>
      <li className="mt-2">Mantenere abitudini alimentari sane;</li>
      <li className="mt-2">
        <p>
        Effettuare una visita dal dentista, in quanto l’eventuale trattamento con radioterapia potrebbe 
        accelerare lo sviluppo di carie o determinare altre complicanze più temibili.
        </p>
      </li>
    </ul>

    <p className="mt-2">
    Il primo approccio terapeutico è fondamentale. Gli specialisti coinvolti
     nella progettazione del trattamento stabiliranno il programma di cura in base allo stadio,
      la localizzazione e le caratteristiche del tumore. La guarigione è l’obiettivo principale, ma si considerano parimenti importanti anche la qualità della vita e la funzionalità degli organi coinvolti, come la voce, la deglutizione, il linguaggio, il gusto e la respirazione. Per tale motivo, la chirurgia demolitiva deve essere sempre 
    pianificata, nella medesima seduta, con una fase di chirurgia ricostruttiva.
    </p>
  </div>

);

export default Fattori;


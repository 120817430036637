import React, { useState } from "react";
import Layout from "../components/layout";
import { patologyTab, faqs } from "../objects/nutrition.js";
import MetaTags from "react-meta-tags";
import { JsonLd } from "react-schemaorg";

export const Nutrition = function () {
  //tabs
  const [actualTab, setActualTab] = useState("alimentazione-preventiva");
  const [_faqs, setFaqs] = useState(faqs);

  const openFaq = (index) => {
    _faqs[index].open = !_faqs[index].open;
    setFaqs([..._faqs]);
  };


  const [activeRecipe, setActiveRecipe] = useState(0);

  const paginatorDot = (index) => {
    return (
      <span className={index === activeRecipe ? 'active' : ''} onClick={() => setActiveRecipe(index)}></span>
    );
  }

  const recipesPaginator = () => {
    return (
      <div className="paginator">
        <div className="paginator__inner">
          {paginatorDot(0)}
          {paginatorDot(1)}
          {paginatorDot(2)}
          {paginatorDot(3)}
          {paginatorDot(4)}

          <div className="paginator__arrows">
            <button className="arrows__left">
              <i className="icon-arrow_left" onClick={() => setActiveRecipe(activeRecipe < 1 ? activeRecipe : activeRecipe - 1)}></i>
            </button>
            <button className="arrows__right">
              <i className="icon-arrow_right" onClick={() => setActiveRecipe(activeRecipe >= 4 ? activeRecipe : activeRecipe + 1)}></i>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <JsonLd
        item={{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          name: "MSD Italia",
          url: "https://www.testacollo.it/",
          sameAs: [
            "https://www.linkedin.com/showcase/msd-salute-1/",
            "https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA",
            "https://www.instagram.com/msd_salute/",
            "https://twitter.com/msdsalute",
            "https://www.facebook.com/msdsalute",
            "https://www.msd-italia.it/",
          ],
          logo: "https://www.testacollo.it/img/headers/logos-header.jpg",
          image: "https://www.testacollo.it/img/home/home1.jpg",
          description:
            "Riconoscere i sintomi, sapere a quali specialisti rivolgersi e a quali esami sottoporsi. Scopri tutto questo sul sito dedicato. Clicca qui!",
          address: {
            "@type": "PostalAddress",
            streetAddress: "Via Vitorchiano 151",
            addressRegion: "RM",
            postalCode: " 00189",
            addressCountry: "Italy",
          },
          "@graph": [
            {
              "@type": "MedicalGuidelineRecommendation",
              evidenceLevel: "https://schema.org/EvidenceLevelA",
              evidenceOrigin: "multiple randomized clinical trials",
              guidelineDate: "2020-12-01T20:00",
              recommendationStrength: "Strong recommendation",
              guidelineSubject: [
                {
                  "@type": "MedicalCondition",
                  name: "head and neck cancer",
                },
              ],
            },
          ],
        }}
      ></JsonLd>
      <MetaTags>
        <title>Percorso nutrizionale | Diagnosi e Trattamenti</title>
        <meta
          name="description"
          content="Recentemente, uno dei fattori considerati determinanti per lo sviluppo dei tumori della testa e del collo, è stato scoperto essere l'alimentazione."
        ></meta>
        <link rel="canonical" href="https://www.testacollo.it/diagnosi/"></link>
      </MetaTags>
      <div
        className="img-header inside-page"
        style={{ backgroundImage: `url("img/headers/nutrizione.jpg")` }}
      ></div>
      <section
        className="page-section section nutrition mpb-0"
        style={{ paddingBottom: `0px` }}
      >
        <h1>Percorso nutrizionale</h1>
        <h2>Dieta e prevenzione</h2>
        <p>
          Recentemente, uno dei fattori associati allo sviluppo
          dei tumori della testa e del collo, è stato scoperto essere
          l'alimentazione. Diventa quindi fondamentale considerare due aspetti:
          la dieta intesa come prevenzione e l'alimentazione più idonea durante
          e dopo i trattamenti specifici per la cura di queste patologie.
        </p>

        <div className="tabsWrapper tabsWrapper--nutrition d-flex space-between">
          {patologyTab.map((item) => (
            <button
              className={
                "tab " +
                (actualTab === item.id ? "active" : "deactivated") +
                " " +
                item.class
              }
              onClick={() => setActualTab(item.id)}
              key={item.id}
            >
              {item.title}
              {actualTab === item.id ? <span></span> : ""}
            </button>
          ))}
        </div>
      </section>

      {/* Alimentazione preventiva */}
      <section
        className={
          "bg-gray-secondary section " +
          (actualTab === "alimentazione-preventiva" ? "d-block" : "d-none")
        }
      >
        <div className="page-section-inner">
          <img src="/img/nutrition/MSD-Illustrazioni-Fase2-A.jpg" alt="Dieta pro-infiammatoria" />
          <h2>Dieta pro-infiammatoria</h2>
          <p>
            Piuttosto che di singoli alimenti, è sicuramente più appropriato
            parlare di modelli dietetici da evitare, come quelli ad{" "}
            <strong>
              elevato indice infiammatorio che possono portare allo sviluppo di
              tumori
              <sup>3</sup>. Di questo tipo fanno parte le diete ricche di carni
              rosse, che contengono ferro, nitriti e nitrati; tutti elementi che
              possono contribuire allo sviluppo di tumori<sup>1</sup>.
            </strong>{" "}
            Questi stessi alimenti possono sviluppare meccanismi di
            cancerogenesi se cotti ad alte temperature<sup>2</sup>.
          </p>
          <p>
            Da tenere sotto controllo sono anche gli alimenti contenenti omega-6
            (semi di girasole, germe di grano, sesamo, noci, arachidi, semi di
            soia, mais, etc.), che sembrano infatti essere correlati
            all’insorgenza di tumori del cavo orale. Un recente studio ha
            comunque dimostrato come{" "}
            <strong>l’equilibrio tra omega-6 e omega-3</strong>&nbsp;
            (mandorle, pinoli, pistacchi, pecan, noci brasiliane, nocciole, olio
            di semi di lino, anacardi, semi di zucca, pesce azzurro, salmone,
            etc) sia in grado regolare l’azione dei fattori cancerogeni e
            diminuire il rischio di tumori del cavo orale<sup>4</sup>.
          </p>
          <img src="/img/nutrition/MSD-Illustrazioni-Fase2-B.jpg" alt="Dieta protettiva" />
          <h2>Dieta protettiva</h2>
          <p>
            Già nel 1997, diversi autorevoli studi scientifici hanno è stato
            confermato che{" "}
            <strong>
              un elevato consumo di frutta e vegetali coincide con un basso
              rischio di incidenza di tumori del cavo orale<sup>5</sup>
            </strong>
            . Tra i meccanismi suggeriti per spiegare questo effetto protettivo
            c’è sicuramente quello antiossidante, capace di ridurre la
            formazione di un gruppo di molecole (ROS) che possono indurre danni
            al DNA<sup>6</sup>. Tra gli alimenti protettivi meritano una
            menzione:
          </p>
          <h3>Vitamine</h3>
          <p>
            Dalle proprietà antiossidanti e antiproliferative, hanno la capacità
            di potenziare il sistema immunitario<sup>7</sup>.
          </p>

          <h3>Frutti gialli</h3>
          <p>
            Un recente studio ha osservato che il consumo di banane ha ridotto
            del 77% il rischio di tumore testa-collo. Questo frutto contiene
            vitamine, acidi fenolici, carotenoidi e ammine biogene con effetto
            antiossidante<sup>8</sup>.
          </p>

          <h3>Frutti rossi</h3>
          <p>
            I frutti rossi, come le more, i mirtilli rossi e l’uva, contengono
            grandi quantità di polifenoli come il resveratrolo, dalle proprietà
            antinfiammatorie, antiossidanti e anticancerogene<sup>9</sup>.
          </p>
          <div className="feature w-98 mt-2 m-0">
            <p>
              <b>
                Per ottenere una riduzione del rischio di incidenza di ogni tipo di tumore, viene
                consigliato il consumo di una quantità di frutta e vegetali pari a 500-600 gr/die.
              </b>
            </p>
          </div>
          <br />
          <h3>Folati</h3>
          <p>
            L’acido folico o folato, anche noto come vitamina B9, si trova
            prevalentemente nei vegetali, cereali, pasta e fagioli. Alcuni studi
            hanno dimostrato che alti livelli di folati correlano un minor
            rischio di incidenza di tumori dell’orofaringe<sup>10</sup>. Al
            contrario, l’alcol interferisce con il trasporto e il metabolismo
            dei folati, di conseguenza i soggetti forti bevitori hanno un
            rischio molto alto di ammalarsi di tumore del cavo orale
            <sup>11</sup>.
          </p>
          <h2>Dieta Mediterranea</h2>
          <p>
            In accordo ai principi del WCRF (World Cancer Research Fund){" "}
            <strong>
              la dieta mediterranea rappresenta un modello da seguire per la
              prevenzione dei tumori.
            </strong>{" "}
            Studi di nutrigenomica stanno inoltre dimostrando che gli elementi
            su cui si basa (consumo di olio extravergine di oliva, cereali,
            frutta e verdura) hanno grande potere antinfiammatorio ed
            antitumorale<sup>12</sup>. In particolare, uno studio condotto su
            768 pazienti ha dimostrato una forte correlazione tra l’aderenza ad
            una dieta Mediterranea e una riduzione del rischio di tumori del
            cavo orale<sup>13</sup>.
          </p>
          <img src="/img/nutrition/MSD-Illustrazioni-Fase2-C.jpg" alt="Dieta mediterranea" />
        </div>
      </section>

      {/* Alimentazione durante la terapia */}
      <section
        className={
          "bg-primary section " +
          (actualTab === "alimentazione-durante-la-terapia"
            ? "d-block"
            : "d-none")
        }
      >
        <div className="page-section-inner">
          <img src="/img/nutrition/MSD-Illustrazioni-Fase2-D.jpg" alt="Dieta e terapia" />
          <h2>La dieta prima, durante e dopo la terapia</h2>
          <p>
            Nel 70% dei casi, i pazienti con tumori del tratto testa collo
            sottoposti a chirurgia, radioterapia e terapia medica oncologica
            presentano uno stato di malnutrizione precoce con perdita di peso e
            conseguente interruzione del trattamento. Questa problematica si
            osserva in maniera più severa durante la radioterapia a causa di
            effetti collaterali come mucosite, dolore, perdita dell’appetito,
            xerostomia (bocca secca), difficoltà a deglutire, mangiare e bere.
            <br />
            Un recente studio effettuato su pazienti con tumore del cavo orale
            ha valutato l’efficacia della riabilitazione tramite esercizio
            fisico e supporto nutrizionale14 durante e dopo la radioterapia.
            <br />
            Al termine dello studio i pazienti sono stati intervistati per
            valutare la percezione degli effetti del supporto nutrizionale
            prima, durante e dopo la radioterapia. Di seguito quanto
            evidenziato:
          </p>
          <h2>Prima della radioterapia</h2>
          <p>
            I pazienti hanno descritto dolore, difficoltà a deglutire, mangiare
            e bere normalmente e perdita di peso. Fin dai primi giorni del
            trattamento sono stati rilevati sintomi come stanchezza, perdita
            dell’appetto e iniziale stato di disidratazione.
          </p>
          <img src="/img/nutrition/olio-evo.jpg" alt="Durante la radioterapia" />
          <h2>Durante la radioterapia</h2>
          <p>
            Nella prima settimana, si è osservato un iniziale alterazione del gusto e produzione
            di muco, secchezza della bocca e difficoltà alla deglutizione. Nella seconda e terza
            settimana peggioramento degli stessi sintomi con sensazione di gusto metallico fino
            alla perdita totale del gusto, dolore alla deglutizione e ulteriore incremento di
            produzione di muco. I pazienti hanno riferito la persistenza dei sintomi anche dopo la
            fine del trattamento, con un graduale, lieve miglioramento dopo 4 settimane.
          </p>
          <p><strong>
            I pazienti hanno anche messo in evidenza il bisogno di maggiori dettagli circa
            l’inizio dei sintomi, la durata degli stessi e il modo di affrontarli. Tutti hanno
            inoltre sottolineato il desiderio di vivere una vita normale, di consumare un
            pasto senza dover frullare ogni alimento, il supporto di un counseling
            nutrizionale e un programma nutrizionale personalizzato per ogni fase del
            trattamento.</strong>
          </p>
          <div className="feature w-98 mt-2 m-0">
            <p>
              <b>
                Nonostante le conoscenze delle gravi conseguenze sullo stato nutrizionale dei
                pazienti, vi è chiaramente la necessita di sensibilizzare gli specialisti, affinché
                ad ogni paziente venga offerta l’opportunità di un counseling nutrizionale,
                dalla diagnosi del tumore alla fine del trattamento, così come nella fase di
                follow-up.
              </b>
            </p>
          </div>
          <h2>Dopo la radioterapia</h2>
          <p>
            Di seguito alcuni consigli per combattere alcuni tra i più comuni effetti collaterali
            causati dai trattamenti.
          </p>
          <div className="feature w-98 mt-2 m-0">
            <p>
              <b>
                Questi consigli non intendono comunque in nessun modo sostituire le terapie
                farmacologiche prescritte dal proprio medico.
              </b>
            </p>
          </div>
          <h3>XEROSTOMIA (bocca secca)<sup>15</sup></h3>
          <p>
            Condizione caratterizzata da particolare e duratura secchezza delle mucose della
            bocca, causata da una prolungata diminuzione della secrezione di saliva.
          </p>
          <p><strong>Consigli alimentari:</strong></p>
          <ul>
            <li>
              Bere almeno 1-1,5 litri di liquidi, acqua o bevande senza zucchero, specialmente
              durante i pasti per facilitare la masticazione e la deglutizione
            </li>
            <li>
              Tenere in bocca un cubetto di ghiaccio per idratare la mucosa
            </li>
            <li>
              Preferire cibi morbidi, ridotti in purea, tritati, cremosi,
              frullati, arricchiti con salse, sughi e condimenti per lenire il
              fastidio e inumidire la bocca
            </li>
            <li>
              Mangiare frutta fresca (come per esempio melone, anguria, pesca)
            </li>
            <li>
              Aggiungere succo di limone a cibi e bevande per stimolare la
              produzione di saliva (se non vi sono ulcere in bocca)
            </li>
            <li>
              Masticare chewing gum o caramelle senza zucchero per stimolare la
              produzione di saliva
            </li>
            <li>
              Evitare gli alimenti speziati o salati
            </li>
            <li>
              Ridurre l’assunzione di caffeina
            </li>
            <li>Ridurre l’assunzione di alcolici</li>
          </ul>
          <h3>MUCOSITE (stomatite)<sup>15</sup></h3>
          <p>
            L’infiammazione del cavo orale è un effetto collaterale molto comune nei pazienti
            affetti da neoplasia del distretto testa-collo. Può manifestarsi sotto forma di afte,
            ulcere, infezioni batteriche e fungine. Oltre ai farmaci normalmente prescritti dal
            medico, per combattere queste infiammazioni è importante prestare attenzione
            all’alimentazione e mantenere una corretta igiene orale. Si consiglia l’utilizzo di
            spazzolini con sete morbide e risciacqui con acqua e bicarbonato (evitando l’utilizzo
            di collutori alcolici).
          </p>
          <p>
            È anche possibile fare risciacqui orali a digiuno con un cucchiaio di olio di girasole.
            Se la pulizia è eseguita correttamente l’olio di girasole diventerà biancastro. Dopo
            aver espulso l’olio, è possibile sciacquarsi un’ultima volta la bocca con acqua per
            eliminare tossine e microbi.
          </p>
          <p><strong>Consigli alimentari:</strong></p>
          <ul>
            <li>
              {" "}
              Preferire cibi morbidi, ridotti in purea, tritati, cremosi,
              frullati, molto umidi e poco salati, facili da masticare o
              deglutire, come omogenizzati, budini, yogurt e gelati. Si possono
              preparare dei frullati di frutta (sbucciata) con yogurt, bevande
              vegetali o vellutate di verdura. In caso di gravi mucositi, è
              consigliato consumare i cereali integrali sotto forma di crema
            </li>
            <li>
              Bere molta acqua durante la giornata, aiutandosi con una cannuccia
              nelle situazioni più difficili
            </li>
            <li>
              Evitare cibi caldi per non irritare bocca o gola. Meglio consumare
              cibi freddi, a temperatura ambiente o tiepidi
            </li>
            <li>
              Utilizzare un cucchiaino per mangiare ed evitare di ingerire
              quantità elevate di cibo
            </li>
            <li>
              {" "}
              Evitare cibi e bevande molto aspri, acidi o salati
            </li>
            <li>
              Evitare spezie e i condimenti irritanti
            </li>
            <li>Evitare cibi secchi</li>
            <li>Evitare le bevande alcoliche</li>
          </ul>
          <h3>NAUSEA E VOMITO</h3>
          <p>
            Possono essere causati sia dalla sede del tumore, sia dai
            trattamenti a cui sono sottoposti i pazienti, ma anche da infezioni,
            fattori psicologici e stato emotivo. Se tali sintomi non sono
            adeguatamente gestiti possono causare perdita di peso,
            disidratazione, problemi metabolici e sbilanciamento di elettroliti.
          </p>
          <p><strong>Consigli alimentari:</strong></p>
          <ul>
            <li>
              Frazionare i pasti in 5-6 spuntini affinché lo stomaco non resti
              mai vuoto, masticando lentamente il cibo
            </li>
            <li>
              È preferibile che qualcuno prepari i pasti per i pazienti, oppure in alternativa si
              possono preparare i pasti prima, congelarli e poi riscaldarli successivamente
            </li>
            <li>
              Evitare i cibi con odori forti
            </li>
            <li>
              Evitare dolci, fritti, cibi grassi e cibi molto saporiti o speziati
            </li>
            <li>
              Evitare bevande alcoliche e contenenti teina e caffeina
            </li>
            <li>
              Preferire cibi a temperatura ambiente o freddi
            </li>
            <li>
              Scegliere cibi salati e asciutti, ad alto contenuto di carboidrati, facili da digerire
            </li>
            <li>
              Può essere utile masticare una galletta di riso integrale, meglio se salata o, in
              alternativa, aggiungere una punta di cucchiaino di miso al pasto
            </li>
            <li>
              Lo zenzero può essere di aiuto (senza eccedere nelle quantità), sotto forma di
              tisana, candito, o da aggiungere alle preparazioni come radice fresca tritata o
              essiccata. Se si hanno però problemi di infiammazioni a livello del cavo orale
              l’utilizzo dello zenzero è sconsigliato
            </li>
            <li>
              In caso di nausea, inizialmente è utile mangiare solo cibi leggeri come minestre o
              budini di crema, e gradualmente aggiungere piccole porzioni dei piatti preferiti
            </li>
            <li>
              Bere molta acqua durante la giornata, non solo durante i pasti, facendo piccoli
              sorsi. Bere anche tè, succhi di frutta e tisane
            </li>
          </ul>
        </div>
      </section>

      {/* Ricette */}
      <section
        className={
          "bg-tertiary section " +
          (actualTab === "ricette" ? "d-block" : "d-none")
        }
      >
        <div className="page-section-inner page-section-recipes">
          <h2>Ricette</h2>
          <div className={"nutrition-recipe " + (activeRecipe === 0 ? "d-block" : "d-none")}>
            <div className="page-section-recipes__header">
              <div className="header__title">
                <h3>Bevanda di gazpacho di avocado e pomodori gialli</h3>
              </div>
              <div className="header__paginator">
                {recipesPaginator()}
              </div>
            </div>
            <div className="nutrition-recipe__details">
              <div className="row">
                <div className="col-50">
                  <h4>Ingredienti per una persona</h4>
                  <ul>
                    <li>150 g pomodori datterini gialli</li>
                    <li>50 g cetrioli</li>
                    <li>25 g pane raffermo</li>
                    <li>100 ml di brodo vegetale in cui bagnare il pane raffermo</li>
                    <li>40 ml di brodo vegetale da aggiungere nel frullatore in modo da regolarne la densità</li>
                    <li>20 ml olio evo</li>
                    <li>100g avocado</li>
                    <li>30g crescenza</li>
                  </ul>
                </div>
                <div className="col-50 details">
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-difficolta.png" alt="Difficoltà" />
                    <h5>Semplice</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tempo.png" alt="Tempo" />
                    <h5>10 minuti <br />30 minuti in frigorifero</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tipo.png" alt="Tipo" />
                    <h5>Bevanda</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-valori.png" alt="Valori" />
                    <div>
                      <h5>Valore energetico e caratteristiche</h5>
                      <p>kcal: 600 - Carboidrati: 21,21g Proteine: 14,02g - Lipidi: 51,65g Fibra totale: 9,28g</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-50">
                <h4>Procedimento</h4>
                <p>
                  Bagnate il pane raffermo con il brodo vegetale, strizzatelo e
                  mettetelo insieme agli altri ingredienti in un frullatore.
                  Aggiungete man mano il brodo vegetale in modo da regolare la
                  densità. Filtrate con un colino a maglia fine e lasciate
                  riposare in frigo per 30 minuti.
                </p>
              </div>
              <div className="col-50">
                <img src="/img/nutrition/MSD-IllustrazioniRicette-Fase2-Gazpacho.jpg" alt="Ricetta Gazpacho" />
              </div>
            </div>
          </div>
          <div className={"nutrition-recipe " + (activeRecipe === 1 ? "d-block" : "d-none")}>
            <div className="page-section-recipes__header">
              <div className="header__title">
                <h3>GHIACCIOLI AL LIMONE VERDE E GIALLO CON ZENZERO E PEPE NERO</h3>
              </div>
              <div className="header__paginator">
                {recipesPaginator()}
              </div>
            </div>
            <div className="nutrition-recipe__details">
              <div className="row">
                <div className="col-50">
                  <h4>Ingredienti per 6 persone</h4>
                  <ul>
                    <li>5 Limoni non trattati</li>
                    <li>3 Limoni verdi non trattati</li>
                    <li>30 gr Radice di zenzero pulita</li>
                    <li>160 ml Acqua</li>
                    <li>10 gr Miele millefiori</li>
                    <li>3 Bacche di pepe</li>
                    <li>Foglioline di mentuccia</li>
                  </ul>
                </div>
                <div className="col-50 details">
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-difficolta.png" alt="Difficoltà" />
                    <h5>Facile</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tempo.png" alt="Tempo" />
                    <h5>45 minuti</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tipo.png" alt="Tipo" />
                    <h5>Snack</h5>
                  </div>
                  <div className="details__feature details__feature--added-indicazioni">
                    <img src="/img/nutrition/ricetta-valori.png" alt="Valori" />
                    <div>
                      <h5>Valore energetico e caratteristiche</h5>
                      <p>kcal: 40pz - Ricchi di sapore grazie alla presenza delle erbe aromatiche e dello zenzero</p>
                    </div>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-indicato-per.png" alt="indicato per le seguenti patologie" />
                    <div>
                      <h5>INDICATO PER </h5>
                      <p>Xerostomia, nausea e vomito</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-50">
                <h4>Procedimento</h4>
                <p>
                  Lavare i limoni accuratamente. Grattugiate la buccia finemente e tenetela da parte. Schiacciate leggermente i limoni sul tavolo e poi spremeteli, filtrate il succo. In un tegame scaldate l’acqua e il miele per poco tempo. Fate in modo che il miele si sciolga, ma il tutto non prenda bollore.
                </p>
                <p>
                  Unite la scorza verde e gialla del limone, lo zenzero pelato e affettato finemente e le bacche di pepe schiacciate. Lasciate in infusione per almeno 30’. Filtrate il tutto al setaccio fine.
                </p>
                <p>
                  Scegliete lo stampo preferito e mettete in congelatore.
                </p>
              </div>
              <div className="col-50">
                <img src="/img/nutrition/MSD-IllustrazioniRicette-Fase2-Ghiaccioli.jpg" alt="Ricetta Ghiaccioli" />
              </div>
            </div>
          </div>
          <div className={"nutrition-recipe " + (activeRecipe === 2 ? "d-block" : "d-none")}>
            <div className="page-section-recipes__header">
              <div className="header__title">
                <h3>INFUSO ALLA CURCUMA E ZENZERO</h3>
              </div>
              <div className="header__paginator">
                {recipesPaginator()}
              </div>
            </div>
            <div className="nutrition-recipe__details">
              <div className="row">
                <div className="col-50">
                  <h4>Ingredienti per 6 persone</h4>
                  <ul>
                    <li>50 cl Acqua</li>
                    <li>10 gr Curcuma</li>
                    <li>5 gr Zenzero</li>
                    <li>1 Cucchiaino di miele bio</li>
                    <li>1 Rametto di menta</li>
                  </ul>
                </div>
                <div className="col-50 details">
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-difficolta.png" alt="Difficoltà" />
                    <h5>Facile</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tempo.png" alt="Tempo" />
                    <h5>15 minuti <br />5 cottura</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tipo.png" alt="Tipo" />
                    <h5>Bevanda</h5>
                  </div>
                  <div className="details__feature details__feature--added-indicazioni">
                    <img src="/img/nutrition/ricetta-valori.png" alt="Valori" />
                    <div>
                      <h5>Valore energetico e caratteristiche</h5>
                      <p>ca. 15 kcal - La presenza dello zenzero aiuta a combattere il senso di nausea; inoltre curcuma e zenzero hanno proprietà antiinfiammatorie, facilitano la digestione e stimolano il sistema immunitario</p>
                    </div>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-indicato-per.png" alt="indicato per le seguenti patologie" />
                    <div>
                      <h5>INDICATO PER </h5>
                      <p>Nausea e vomito</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-50">
                <h4>Procedimento</h4>
                <p>
                  Scaldare l’acqua fino a 90°C.
                </p>
                <p>
                  Grattugiare la radice di curcuma e la radice di zenzero. Porle in un filtro.
                  Immergere il filtro nell’acqua calda e attendere circa 5’ per ottenere un’infusione ricca. Nelle giornate calde è possibile refrigerare l’infuso e berlo fresco durante la giornata. Scegliete sempre radici di provenienza italiana o bio. Le radici che arrivano da paesi extra UE potrebbero contenere pesticidi.
                </p>
              </div>
              <div className="col-50">
                <img src="/img/nutrition/MSD-IllustrazioniRicette-Fase2-Infuso.jpg" alt="Ricetta infuso" />
              </div>
            </div>
          </div>
          <div className={"nutrition-recipe " + (activeRecipe === 3 ? "d-block" : "d-none")}>
            <div className="page-section-recipes__header">
              <div className="header__title">
                <h3>CREMOSO DI SOIA AL PISTACCHIO</h3>
              </div>
              <div className="header__paginator">
                {recipesPaginator()}
              </div>
            </div>
            <div className="nutrition-recipe__details">
              <div className="row">
                <div className="col-50">
                  <h4>Ingredienti per 4 persone</h4>
                  <ul>
                    <li>250g latte soia</li>
                    <li>200g pistacchio</li>
                  </ul>
                </div>
                <div className="col-50 details">
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-difficolta.png" alt="Difficoltà" />
                    <h5>Facile</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tempo.png" alt="Tempo" />
                    <h5>20 minuti <br />40 cottura</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tipo.png" alt="Tipo" />
                    <h5>Dessert</h5>
                  </div>
                  <div className="details__feature details__feature--added-indicazioni">
                    <img src="/img/nutrition/ricetta-valori.png" alt="Valori" />
                    <div>
                      <h5>Valore energetico e caratteristiche</h5>
                      <p>kcal 324 – Carboidrati: 4,55 g – Proteine 10,86 g – Lipidi: 29,23 g – Fibra Tot 5,3g – Magnesio: 94,62mg</p>
                    </div>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-indicato-per.png" alt="indicato per le seguenti patologie" />
                    <div>
                      <h5>INDICATO PER </h5>
                      <p>Mucosite e stanchezza da ipomagnesemia</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-50">
                <h4>Procedimento</h4>
                <p>
                  Versate in un recipiente 250ml latte di soia freddo.
                </p><p>
                  Aggiungete il pistacchio tritato.
                </p><p>
                  Con l’aiuto di un minipimer miscelate bene il tutto fino ad ottenere un composto denso e spumoso, chiamato “cremoso”.
                </p>
                <p>
                  Servire freddo.
                </p>
              </div>
              <div className="col-50">
                <img src="/img/nutrition/MSD-IllustrazioniRicette-Fase2-Cremoso.jpg" alt="Ricetta cremoso pistacchio" />
              </div>
            </div>
          </div>
          <div className={"nutrition-recipe " + (activeRecipe === 4 ? "d-block" : "d-none")}>
            <div className="page-section-recipes__header">
              <div className="header__title">
                <h3>MINESTRA DI FUNGHI</h3>
              </div>
              <div className="header__paginator">
                {recipesPaginator()}
              </div>
            </div>
            <div className="nutrition-recipe__details">
              <div className="row">
                <div className="col-50">           
                  <h4>Ingredienti per 6 persone</h4>
                  <ul>
                    <li>250 gr Funghi misti</li>
                    <li>2 Patate (250 gr circa)</li>
                    <li>Prezzemolo</li>
                    <li>1/2 l Brodo</li>
                    <li>2 Cucchiai d’olio extravergine di oliva</li>
                    <li>2 Spicchi d’aglio</li>
                    <li>1 Cucchiaio di salsa di soia</li>
                    <li>Sale</li>
                    <li>Pepe</li>
                  </ul>
                </div>
                <div className="col-50 details">
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-difficolta.png" alt="Difficoltà" />
                    <h5>Facile</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tempo.png" alt="Tempo" />
                    <h5>20 minuti <br />40 cottura</h5>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-tipo.png" alt="Tipo" />
                    <h5>Primo piatto</h5>
                  </div>
                  <div className="details__feature details__feature--added-indicazioni">
                    <img src="/img/nutrition/ricetta-valori.png" alt="Valori" />
                    <div>
                      <h5>Valore energetico e caratteristiche</h5>
                      <p>ca. 90 kcal a porzione - ricco in fibre e proteine vegetali</p>
                    </div>
                  </div>
                  <div className="details__feature">
                    <img src="/img/nutrition/ricetta-indicato-per.png" alt="indicato per le seguenti patologie" />
                    <div>
                      <h5>INDICATO PER </h5>
                      <p>Mucosite</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-50">
                <h4>Procedimento</h4>
                <p>
                Pulire i funghi eliminando la parte terrosa del gambo con le radichette, lavarli brevemente sotto acqua fresca corrente, rimuovere la parte coriacea dei gambi e affettarli. Lavare le patate, sbucciarle e tagliarle a cubetti di un centimetro circa di lato. Lavare il prezzemolo, selezionarne le foglie e tritarle finemente su un tagliere con una mezzaluna. Scaldare il brodo. In una pentola da minestra mettere due cucchiai d’olio e farvi rosolare l’aglio spellato e leggermente schiacciato con i denti di una forchetta. Quando si sarà uniformemente dorato, toglierlo e aggiungere le patate e i funghi. Far rosolare per un paio di minuti a fiamma vivace, mescolando di continuo. Fare attenzione perché le patate tendono ad attaccarsi. Aggiungere un cucchiaio di salsa di soia, mescolare bene, quindi aggiungere il restante brodo caldo. Unire un pizzico di sale, un cucchiaio di prezzemolo tritato e cuocere coperto a fiamma media per 15 minuti. Girare di tanto in tanto. Trascorso il tempo di cottura regolare di sale, unire una grattugiata di pepe e far riposare 5 minuti prima di servire.
                </p>
              </div>
              <div className="col-50">
                <img src="/img/nutrition/MSD-IllustrazioniRicette-Fase2-Funghi.jpg" alt="Ricetta cremoso pistacchio" />
              </div>
            </div>
          </div>
        </div>

      </section>

      <section
        className={
          "bg-blue section " +
          (actualTab === "nutrizione-artificiale" ? "d-block" : "d-none")
        }
      >
        <div className="page-section-inner">
          <h2>
            L’importanza della nutrizione nei pazienti con tumori testa-collo
          </h2>
          <p>
            In passato la malnutrizione era considerata un ineluttabile effetto
            collaterale della malattia a cui rassegnarsi, non prevenibile e
            irreversibile. Oggi, invece, un corretto percorso nutrizionale è
            considerato uno degli aspetti su cui intervenire tempestivamente,
            come parte integrante delle cure oncologiche, per impedire e
            contrastare l’evoluzione della malattia.
          </p>
          <img src="/img/nutrition/MSD-Illustrazioni-Fase2-F.jpg" alt="importanza della nutrizione" />
          <h2>
            Le cause e gli effetti della malnutrizione nei malati oncologici
          </h2>
          <p>
            Le cause della malnutrizione nel paziente oncologico possono essere
            molteplici:
          </p>

          <ul>
            <li>Localizzazione della neoplasia</li>
            <li>Localizzazione delle metastasi</li>
            <li>Trattamenti terapeutici</li>
          </ul>
          <p>
            Al momento della diagnosi,{" "}
            <strong>
              i pazienti affetti da tumi pazienti affetti da tumori del
              distretto testa-collo presentano spesso una variabile
              compromissione dello stato di nutrizione
            </strong>{" "}
            e una ridotta qualità di vita, parametri che tendono a peggiorare
            nel corso dei trattamenti. È anche noto che un alterato stato di
            nutrizione si associ a una maggiore mortalità e a una più frequente
            necessità di sospendere i trattamenti oncologici.
          </p>
          <p>
            La malnutrizione è determinata da una profonda discrepanza tra i
            fabbisogni nutrizionali specifici e la reale utilizzazione dei
            nutrienti assunti. È infatti possibile riscontrare uno stato di
            malnutrizione sia per eccesso sia per difetto.{" "}
            <strong>
              Il più frequente tipo di discrepanza è la malnutrizione
              proteico-energetica
            </strong>{" "}
            che, in presenza di una patologia come una neoplasia, può sfociare
            nel quadro di cachessia irreversibile. Nel paziente affetto da
            neoplasia, la perdita di peso dipende da vari fattori ed è associata
            ad una diminuita sopravvivenza, ad una scarsa risposta e/o
            tolleranza ai trattamenti radioterapici e chemioterapici, ad una
            ridotta qualità di vita, ad una più alta incidenza e durata di
            ospedalizzazione.
          </p>
          <p>
            <strong>
              Un ruolo determinante è svolto dall’ipofagia, cioè dalla riduzione
              dell’assunzione di cibo, che può dipendere dall’impedimento
              nell’alimentazione naturale a causa di un’ostruzione meccanica,
              dagli effetti collaterali dei trattamenti o alla perdita del
              desiderio di alimentarsi, nota come anoressia neoplastica.
            </strong>{" "}
            Oltre che dalla ridotta assunzione di alimenti, tuttavia,
            l’alterazione dello stato nutrizionale può essere causato anche da
            alterazioni del metabolismo. Va infine considerata una marcata
            riduzione della massa muscolare, che si manifesta come astenia,
            ridotta funzione fisica, riduzione della qualità della vita, fino a
            una progressiva inabilità. Gli effetti della malnutrizione sono
            evidenti dal fatto che una percentuale superiore ai 2/3 dei pazienti
            manifesta perdita di peso, e il 20-30% di essi muore per gli effetti
            correlati.
          </p>

          <p>
            A testimonianza dell’importanza che il supporto nutrizionale sta
            acquistando per i pazienti, negli ultimi anni molte società
            scientifiche e associazioni si sono impegnate nel produrre e
            diffondere materiale informativo sulla nutrizione in oncologia. La
            Federazione delle Associazioni di Volontariato in Oncologia (FAVO),
            l’Associazione Italiana di Oncologia medica (AIOM) e la Società
            Italiana di Nutrizione Artificiale e Metabolismo (SINPE) hanno
            infatti messo a punto 
            <strong>
              la Carta dei Diritti del Paziente Oncologico all’appropriato e
              tempestivo supporto nutrizionale
            </strong>
            , per spiegare a malati e familiari i corretti principi in tema di
            nutrizione, rispondere alle richieste sull’alimentazione e spiegare
            come ricevere corrette prescrizioni nutrizionali.
          </p>
          <p>
            <a
              href="/pdf/MSD-TC-CartaDiritti-SupportoNutrizionale.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn btn-primary btn-arrow-down">
                SCARICA IL PDF
              </button>
            </a>
            <br />
            <br />
          </p>
          <h2>Il trattamento nutrizionale e la nutrizione artificiale</h2>
          <p>
            Se l’alimentazione naturale è ancora possibile, è consigliata
            l’elaborazione di un piano dietetico personalizzato da parte di
            personale specializzato (dietisti e medici nutrizionisti).
          </p>

          <p>
            <strong>
              Se le quantità di alimenti assunti naturalmente sono insufficienti
              a soddisfare i fabbisogni nutrizionali del paziente,
              l’alimentazione naturale va integrata con prodotti specifici da
              assumere previa prescrizione specialistica.
            </strong>{" "}
            Se anche quest’approccio risultasse insufficiente, il medico
            potrebbe consigliare il ricorso alla nutrizione artificiale,
            enterale o parenterale, in ospedale o a domicilio.
          </p>

          <p>
            <strong>La nutrizione artificiale (NA)</strong> è una procedura
            terapeutica mediante la quale è possibile soddisfare integralmente i
            fabbisogni nutrizionali di pazienti altrimenti non in grado di
            alimentarsi sufficientemente per via naturale. La nutrizione
            artificiale si divide in:
          </p>

          <ul>
            <li>
              <strong style={{ color: "#00857a" }}>
                Nutrizione parenterale (NP)
              </strong>
              , ossia quella che consiste nel nutrire il paziente con delle
              miscele infuse nel circolo venoso tramite dei cateteri;
            </li>
            <li>
              <strong style={{ color: "#00857a" }}>
                Nutrizione enterale (NE)
              </strong>
              , la quale comporta invece l’introduzione delle formulazioni
              nutrizionali in diverse parti dell’intestino tramite delle sonde,
              necessitano di precisi protocolli terapeutici e di un monitoraggio
              programmato, personalizzati a seconda dello stato metabolico e
              delle esigenze nutrizionali del singolo paziente.
            </li>
          </ul>

          <p>
            La nutrizione artificiale non vieta la possibilità, se correttamente
            valutata, di introdurre piccole quantità di alimenti per via
            naturale anche a scopo riabilitativo.
          </p>
          <h2>La nutrizione Enterale</h2>
          <p>
            La Nutrizione Enterale (NE) rappresenta la tipologia di nutrizione
            artificiale più diffusa e di prima scelta nei pazienti affetti da
            neoplasie del distretto testa-collo e permette di somministrare i
            nutrienti direttamente nello stomaco o nell’intestino, tramite sonde
            specifiche.
          </p>

          <p>La sonda può essere:</p>
          <h3>• Introdotta dal naso con sondino naso-gastrico (SNG)</h3>
          <p>
            Rappresenta la tecnica più utilizzata, la più semplice da attuare e
            la meno traumatizzante. Il SNG è realizzato in materiale morbido,
            flessibile, resistente, adatto per la somministrazione di miscele
            nutrizionali di consistenza liquida. Viene introdotto attraverso la
            narice fino a raggiungere lo stomaco, sede naturale di arrivo degli
            alimenti. È indicato per la NE a breve termine (6-7 settimane); in
            pazienti particolarmente complessi, se ben tollerato, può rimanere
            in sede per periodi più lunghi anche se dopo 6 settimane deve essere
            sostituito.
          </p>
          <h3>• Posizionata nello stomaco tramite gastrostomia (PEG)</h3>
          <p>
            Richiede un intervento in endoscopia per creare una comunicazione
            tra la cavità interna (stomaco) e l’esterno attraverso la cute.
            <br />
            Può essere eseguita in modo:
            <br />- chirurgico
            <br />- endoscopico
            <br />
            Si utilizza per la somministrazione di nutrienti, farmaci e per
            decomprimere lo stomaco. La PEG ha una durata media di circa 1 anno
            ed è indicata per la NE in ambito domiciliare.
          </p>
          <h3>• Posizionata nel digiuno (digiunostomia)</h3>
          <p>
            Richiede un intervento chirurgico minore per creare una
            comunicazione diretta tra il primo tratto dell’intestino, il
            digiuno, e la parete addominale anteriore esterna attraverso la
            quale si introduce la sonda nutrizionale. Viene allestita quando lo
            stomaco non è utilizzabile.
          </p>
          <p>
            Può essere utilizzata per infondere:
            <br />- miscele per nutrizione enterale pronte del commercio
            <br />- integratori modulari
            <br />
          </p>

          <p>
            Sono invece sconsigliati gli alimenti freschi frullati a causa del
            maggior rischio di contaminazione batterica, del minore apporto
            nutrizionale e della possibile formazione di bolle, incrostazioni ed
            ostruzioni all’interno della sonda nutrizionale.
          </p>
          <h2>La pompa nutrizionale</h2>
          <p>È un apparecchio medicale che consente la somministrazione costante della
miscela a velocità programmabile. Funziona a elettricità in rete e/o a batteria. Può
essere fissa o portatile se contenuta in zainetti o marsupi, consentendo maggiore
libertà di spostamenti. Una corretta pulizia e gestione della pompa è fondamentale
per una buona nutrizione.</p>
        </div>
      </section>

      <section
        className={
          "page-section-white section " +
          (actualTab === "faq" ? "d-block" : "d-none")
        }
      >
        <div className="page-section-inner">
          {_faqs.map((item, index) => (
            <div
              className={
                "nutrition-faq" + (item.open ? " nutrition-faq--open" : "")
              }
              key={index}
            >
              <h2 className="nutrition-faq__title">
                {index + 1 + ". " + item.title}
              </h2>
              <div className="nutrition-faq__content">
                <button
                  className="content__toggle"
                  onClick={() => openFaq(index)}
                ></button>
                <div
                  className="content__inner"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section class="page-section-gray additional">
        Contenuti realizzati con il contributo di: Prof. Salvatore Artale, Direttore S.C. Oncologia Ospedale di Gallarate, ASST Valle Olona, Gallarate; <br />Prof. Riccardo Caccialanza, Direttore UOC Dietetica e Nutrizione Clinica Fondazione IRCCS Policlinico San Matteo, Pavia; <br />Prof. Paolo Pedrazzoli, Direttore S.C. Oncologia, Fondazione IRCCS Policlinico San Matteo, Pavia
      </section>
      <section className="page-section-gray page-section-gray-biblio section">
        <h2>Bibliografia</h2>
        <ol className="esm-font gray-bullets">
          <li>
            Chan, T.Y.K. Vegetable-Borne Nitrate and Nitrite and the Risk of Methaemoglobinaemia. Toxicol. Lett. 2011, 200, 107–108.
          </li>
          <li>
            Leszczynska, T.; Filipiak-Florkiewicz, A.; Cieslik, E.; et al. Effects of some Processing Methods on Nitrate and Nitrite Changes in
            Cruciferous Vegetables. J. Food Compost. Anal. 2009, 22, 315–321.
          </li>
          <li>
            Hsu, J.; Arcot, J.; Alice Lee, N. Nitrate and Nitrite Quantification from Cured Meat and Vegetables and their Estimated Dietary Intake in
            Australians. Food Chem. 2009, 115, 334–339.
          </li>
          <li>
            McClain, K.M.; Bradshaw, P.T.; Khankari, N.K.; et al. Fish/Shellfish Intake and the Risk of Head and Neck Cancer. Eur. J. Cancer Prev.
            2019, 28, 102–108.
          </li>
          <li>
            Glade, M.J. Food, Nutrition, and the Prevention of Cancer: A Global Perspective. American Institute for Cancer Research/World Cancer
            Research Fund, American Institute for Cancer Research, 1997. Nutrition 1999, 15, 523–526.
          </li>
          <li>
            Gonzales, J.F.; Barnard, N.D.; Jenkins, D.J.A.; et al. Applying the Precautionary Principle to Nutrition and Cancer. J. Am. Coll. Nutr.
            2014, 33, 239–246.
          </li>
          <li>
            Grosso, G.; Bei, R.; Mistretta, A.; et al Effects of Vitamin C on Health: A Review of Evidence. Front. Biosci. 2013, 18, 1017–1029.
          </li>
          <li>
            De Galvão Podestá, O.P.; Peres, S.V.; Salaroli, L.B.; et al. Consumption of Minimally Processed Foods as Protective Factors in the
            Genesis of Squamous
            Cell Carcinoma of the Head and Neck in Brazil. PLoS ONE 2019, 14, e0220067.
          </li>
          <li>
            Bishayee, A.; Politis, T.; Darvesh, A.S. Resveratrol in the Chemoprevention and Treatment of Hepatocellular Carcinoma. Cancer Treat.
            Rev. 2010, 36, 43–53.
          </li>
          <li>
            Shanmugham, J.R.; Zavras, A.I.; Rosner, B.A.; et al. Alcohol-Folate Interactions in the Risk of Oral Cancer in Women: A Prospective
            Cohort Study. Cancer Epidemiol. Biomark. Prev. 2010, 19, 2516–2524.
          </li>
          <li>
            Bravi, F.; Bosetti, C.; Filomeno, M.; et al. Foods, Nutrients and the Risk of Oral and Pharyngeal Cancer. Br. J. Cancer 2013, 109,
            2904–2910.
          </li>
          <li>World Cancer Research Fund. Available at: &nbsp;
            <a
              rel="noopener noreferrer"
              href="https://www.wcrf.org/dietandcancer/cancer-prevention-recommendations"
              target="_blank"
            >
              https://www.wcrf.org/dietandcancer/cancer-prevention-recommendations. Accessed 16
              Feb, 2020.
            </a>
          </li>
          <li>
            Filomeno, M.; Bosetti, C.; Garavello,W.; et al. The Role of a Mediterranean Diet on the Risk of Oral and Pharyngeal Cancer. Br. J.
            Cancer 2014, 111, 981–986.
          </li>
          <li>
            Sandmael, JA; Kari, S; Bye, A; et al. Nutritional experiences in head and neck cancer patients. Eur J Cancer Care. 2019;28, 1-11,
            e13168.
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="http://www.oncologiaecucina.org/"
              target="_blank"
            >
              www.oncologiaecucina.org
            </a>&nbsp;
            Cliccando su questo link state abbandonando il sito testacollo.it, le informazioni contenute nel nuovo sito
            prescelto possono non essere conformi al D. Lgs. 219/06 che regola la pubblicità sui medicinali per uso umano e le opinioni espresse non
            riflettono necessariamente le posizioni della MSD Italia S.r.l., né sono da essa avallate.
          </li>
        </ol>
      </section>
    </Layout>
  );
};

export default Nutrition;

import React from "react";


const EffettiCollaterali = () => (

  <div>
              <div className="my-2">
                <img src="/img/patology/tabs/followup.png" alt="" />
              </div>
              <p className="text-green my-2">
                <b>Effetti collaterali della chirurgia</b>
              </p>
              <p>
                La natura degli effetti indesiderati dipende dalla localizzazione
                del tumore. Potrebbero insorgere problemi funzionali o danni
                evidenti di natura estetica. Ad esempio, l’intervento potrebbe
                interferire sulla voce, sulla capacità di parlare, sull’udito e sulla
                capacità di masticare e deglutire. Tuttavia, molte procedure
                possono essere effettuate senza compromettere l’aspetto
                esteriore e la funzionalità delle aree coinvolte.
              </p>

              <p  className="text-green my-2">
                <b>Effetti collaterali della chemioterapia</b>
              </p>
              <p>
                La chemioterapia può avere effetti tossici che comprendono
                malessere generalizzato, temporanea perdita dei capelli,
                nausea, vomito, mucosite e gastroenterite. L’utilizzo
                combinato di chemioterapia e radioterapia aumenta il rischio
                di effetti collaterali perché gli effetti indesiderati di un
                trattamento si sommano a quelli dell’altro.
              </p>

              <p id="radioterapia" className="text-green my-2">
                <b>Effetti collaterali della radioterapia</b>
              </p>
              <p>
                La radioterapia può provocare sia complicanze a breve
                termine (come arrossamento o gonfiore dell’area trattata,
                bocca secca, mucosite, alterazione del gusto, infezioni del
                cavo orale) che complicanze a lungo termine, proporzionate al
                danno arrecato alle strutture circostanti (danni ai denti e alle
                mandibole, che però possono essere prevenuti con un
                adeguato trattamento dentale).
                L’irradiazione nell’area testa-collo, effettuata come terapia per
                contrastare i tumori di bocca, naso e gola, può avere alcuni
                effetti collaterali specifici, tra cui:

              </p>
              <ul className="mt-2">
                <li>
                  <p><b>Alterazione della Dentatura</b> </p>
                </li>
                <p>L’irradiazione può determinare alterazioni della dentatura, per
                  cui sono necessari controlli odontoiatrici più frequenti. Lavare
                  delicatamente i denti con uno spazzolino a setole morbide e
                  usando un dentifricio al fluoro cinque-sei volte al giorno può
                  dare sollievo.</p>

                <li>
                  <p><b>Dolorabilità del cavo orale</b> </p>
                </li>
                <p>Le cellule che rivestono il cavo orale sono sensibili alle
                  radiazioni, che possono anche facilitare l’insorgenza di
                  infezioni. Un igienista dentale può essere di aiuto, se
                  necessario prescrivendo anche lavaggi regolari del cavo orale,
                  medicamenti specifici e analgesici. È sicuramente consigliato
                  evitare cibi o bevande molto freddi o molto caldi, piccanti e
                  speziati, così come alcolici e tabacco durante tutta la durata
                  del trattamento e per alcune settimane dopo la sua
                  conclusione.</p>

                <li>
                  <p><b>Alterazione del gusto</b> </p>
                </li>
                <p>Anche le papille gustative possono risentire della radioterapia,
                  alterando la percezione del sapore dei cibi, che per alcuni
                  pazienti diventa “metallico”. Ci si può rivolgere ad un Dietista
                  per contrastare questo effetto collaterale.</p>

                <li>
                  <p><b>Secchezza delle fauci</b> </p>
                </li>
                <p>Le ghiandole salivari possono risentire del trattamento,
                  influendo sulla produzione di saliva e quindi sulla capacità di
                  ingoiare e masticare. Questa situazione può protrarsi anche
                  per diversi mesi dopo la fine della terapia e in alcuni casi può
                  diventare permanente. La saliva artificiale spray per umettare
                  la bocca può essere un valido rimedio.</p>

                <li>
                  <p><b>Perdita dell’appetito e calo di peso</b> </p>
                </li>
                <p>La combinazione degli effetti collaterali sopracitati può
                  determinare una riduzione dell’appetito e conseguente perdita
                  di peso, problemi per i quali è possibile rivolgersi ad un
                  Nutrizionista. Se l’alimentazione per bocca risultasse
                  particolarmente difficile, potrebbe essere necessario ricorrere
                  alla nutrizione artificiale.</p>

                <li>
                  <p id="voce"><b>Alterazioni della voce</b> </p>
                </li>
                <p>La radioterapia per i tumori della laringe può rendere anche
                  totalmente afoni. Nella maggior parte dei casi queste
                  alterazioni sono temporanee e la voce dovrebbe ritornare
                  normale entro qualche settimana dopo la conclusione del
                  trattamento.</p>

                <li>
                  <p><b>Caduta dei capelli</b> </p>
                </li>
                <p>Di solito i capelli cominciano a cadere dopo 2/3 settimane
                  dall’inizio della terapia e nella maggior parte dei casi
                  ricrescono nel giro di 2/3 mesi dalla conclusione. A volte i
                  nuovi capelli potrebbero essere leggermente diversi per colore
                  e struttura.</p>

                <li>
                  <p><b>Il supporto del logopedista</b> </p>
                </li>
                <p>Se la radioterapia causa uno o più sintomi legati a disfagia o 
                <a href="/DisfagiaDisfonia" className="mob-link"> disfonia</a> puoi chiedere il supporto di un
                  logopedista che saprà come affrontarli al meglio in modo da
                  limitarne l’impatto sulla qualità di vita.</p>

              </ul>

            </div>



);

export default EffettiCollaterali;


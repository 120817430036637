import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages";
import Patology from "../pages/patology";
import DisfagiaDisfonia from "../pages/DisfagiaDisfonia"
import Quiz from "../pages/quiz";
import Diagnose from "../pages/diagnose";
import Rights from "../pages/rights";
import Nutrition from "../pages/nutrition"
import Farmacovigilanza from "../pages/farmacovigilanza"
import Privacypolicy from "../pages/privacy-policy"
import Trattamenti from "../components/layout/tabs/diagnose/trattamenti/trattamenti";
import Fattori from "../components/layout/tabs/diagnose//trattamenti/trattamenti-fattori";
import TipologieCure from "../components/layout/tabs/diagnose//trattamenti/trattamenti-tipologie-cure";
import ProgrammiTrattamento from "../components/layout/tabs/diagnose//trattamenti/trattamenti-programmi-di-trattamentoy";
import EffettiCollaterali from "../components/layout/tabs/diagnose//trattamenti/trattamenti-effetti-collaterali";
import DopoTrattamento from "../components/layout/tabs/diagnose/trattamenti/trattamenti-dopo-trattamento";
import Sintomi from "../components/layout/tabs/diagnose/sintomi";
import Diagnosi from "../components/layout/tabs/diagnose/diagnosi/diagnosi";
import TumoriDelCavoOrale from "../components/layout/tabs/diagnose/diagnosi/diagnosi-tumori-del-cavo-orale";
import TumoriDellaLaringe from "../components/layout/tabs/diagnose/diagnosi/diagnosi-tumori-della-laringe";
import TumoriDellIpoFaringe from "../components/layout/tabs/diagnose/diagnosi/diagnosi-tumori-dell-ipofaringe";
import TumoriDelleGhiandoleSalivari from "../components/layout/tabs/diagnose/diagnosi/diagnosi-tumori-delle-ghiandole-salivari";
import Specialisti from "../components/layout/tabs/diagnose/specialisti";
import Prognosi from "../components/layout/tabs/diagnose/prognosi";
import Esami from "../components/layout/tabs/diagnose/esami";

export default function() {
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route path="/patologia-prevenzione" element={<Patology/>} />
      <Route path="/diagnosi" element={<Diagnose/>} >
        <Route index element={<Navigate to="sintomi" replace />} />
        <Route path="sintomi" element={<Sintomi/>} />
       
        <Route path="specialisti" element={<Specialisti/>} />
      
        <Route path="esami" element={<Esami/>} />

        <Route path="diagnosi" element={<Diagnosi/>} >
          <Route index element={<Navigate to="tumori-del-cavo-orale" replace />} />
          <Route path="tumori-del-cavo-orale" element={<TumoriDelCavoOrale/>} />
          <Route path="tumori-della-laringe" element={<TumoriDellaLaringe/>} />
          <Route path="tumori-dell-ipofaringe" element={<TumoriDellIpoFaringe/>} />
          <Route path="tumori-delle-ghiandole-salivari" element={<TumoriDelleGhiandoleSalivari/>} />         
        </Route>
        
        <Route path="prognosi" element={<Prognosi/>} />

        <Route path="trattamenti" element={<Trattamenti/>} >
          <Route index element={<Navigate to="fattori-rischio" replace />} />
          <Route path="fattori-rischio" element={<Fattori/>} />
          <Route path="tipologia-cure" element={<TipologieCure/>} />
          <Route path="programmi-trattamento" element={<ProgrammiTrattamento/>} />
          <Route path="effetti-collaterali" element={<EffettiCollaterali/>} />
          <Route path="dopo-trattamento" element={<DopoTrattamento/>} />
        </Route>

      </Route>
      <Route path="/DisfagiaDisfonia" element={<DisfagiaDisfonia/>} />
      <Route path="/quiz-prevenzione" element={<Quiz/>} />
      <Route path="/diritti-paziente-oncologico" element={<Rights/>} />
      <Route path="/percorso-nutrizionale" element={<Nutrition/>} />
      <Route path="/farmacovigilanza" element={<Farmacovigilanza/>} />
      <Route path="/privacy-policy" element={<Privacypolicy/>} />
    </Routes>
    </BrowserRouter>
  );
}

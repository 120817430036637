import React from "react";


const TumoriDelCavoOrale = () => (
  <section>

    <div>
              <p>
                Per questa tipologia di tumore la diagnosi generalmente non è
                complessa da eseguire: è sufficiente una semplice{" "}
                <b>biopsia in anestesia locale.</b> In pochi minuti e con poco
                dolore è possibile quindi eseguire una diagnosi istologica.
                Inoltre, nel caso di tumore alle corde vocali, è possibile
                eseguire una diagnosi precoce con <b>rinofibrolaringoscopia.</b>{" "}
                Successivamente all’ecografia, la risonanza magnetica e la PET
                (le metodiche di imaging) permetteranno di eseguire una corretta
                stadiazione. Nessuna tipologia di esami del sangue potrà
                rilevare la presenza di un <b>tumore del cavo orale.</b>
              </p>

              <div className="feature-blue mt-2 sm-font">
                <p>
                  Per avere la certezza della presenza di un tumore{" "}
                  <b>
                    bisogna necessariamente attendere i risultati della biopsia
                  </b>
                  . Solitamente una massa cervicale viene analizzata con{" "}
                  <b>agoaspirato</b>. Le lesioni orali, invece, vengono di norma
                  analizzate con una{" "}
                  <b>biopsia incisionale o tramite citologia esfoliativa</b>. In
                  caso di possibili tumori a livello di rinofaringe, orofaringe
                  e laringe si esegue una <b>biopsia endoscopica</b>.
                </p>
              </div>
            </div>
</section >
 


);

export default TumoriDelCavoOrale;


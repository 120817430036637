export const slideHome = [
    {
      src:'/img/home/home1.jpg',
      id:'slide-1-home',
      title:'CON I TUMORI \nDI BOCCA, \nNASO E GOLA \nNON SI SCHERZA.',
    },
    {
      src:'/img/home/home2.jpg',
      id:'slide-2-home',
      title:'CON I TUMORI \nDI BOCCA, \nNASO E GOLA \nNON SI SCHERZA.',
    },
    {
      src:'/img/home/home3.jpg',
      id:'slide-3-home',
      title:'CON I TUMORI \nDI BOCCA, \nNASO E GOLA \nNON SI SCHERZA.',
    }
  ]

export const sliderComunicazione = [
    {
      src:'/img/home/MSD-A41_resized.jpg',
      id:'slide-1',
      title:'slide-1',
    },
    {
      src:'/img/home/MSD-A42_resized.jpg',
      id:'slide-2',
      title:'slide-1',
    },
    {
      src:'/img/home/MSD-A43_resized.jpg',
      id:'slide-3',
      title:'slide-1',
    }
  ]

export default {slideHome,sliderComunicazione};
import React from "react";
import Router from "./router";
import "./App.scss";


function App() {
  return <Router />;
}

export default App;

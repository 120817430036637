export const patologySlideshow = [
  {
    title:'Tabacco',
    img:'/img/patology/slider/slide1.jpg',
    text:'Il rischio dipende dalla quantità di tabacco consumata e dalla durata del suo utilizzo. Si riduce progressivamente, fino ad avvicinarsi a quello dei non fumatori, dopo 15 anni dalla cessazione dell’abitudine.',
    feature:'<p>Non fumare</p>'
  },
  {
    title:'Alcol',
    img:'/img/patology/slider/slide2.jpg',
    text:'Il rischio correlato all’uso di bevande alcoliche aumenta nel tempo ed è proporzionale alla quantità di alcol assunta. Chi beve molto ha un rischio 5 volte maggiore di sviluppare un tumore del cavo orale e 7 volte maggiore di sviluppare un tumore della faringe.',
    feature:'Limitare il consumo di alcolici'
  },
  {
    title:'Papillomavirus umano (HPV)',
    img:'/img/patology/slider/slide3.jpg',
    text:'È stata riscontrata un’infezione da papillomavirus in soggetti con tumore dell’orofaringe (31% dei casi), meno frequentemente in soggetti con tumori del cavo orale e della laringe (rispettivamente <10% e 2,4%).',
  },
  {
    title:'Dieta',
    img:'/img/patology/slider/slide4.jpg',
    text:'Un’alimentazione errata, specialmente se povera di vitamina A e di vitamine del gruppo B, può condizionare lo sviluppo della patologia.',
    feature:'Consumare molta frutta e verdura, in particolare alimenti ricchi di carotenoidi, come peperoni, pomodori, carote e zucca'
  },
  {
    title:'Virus di Epstein-Barr',
    img:'/img/patology/slider/slide5.jpg',
    text:'Il virus di Epstein-Barr (EBV), un tipo di herpes virus che si trasmette attraverso la saliva, è associato ai tumori del tratto naso-faringeo.',
    feature:'Sottoporre all’attenzione del medico eventuali lesioni alla bocca, anche se indolori o di ridotte dimensioni, come noduli, afte, ulcere, placche ed escrescenze varie'
  },
  {
    title:'Esposizione professionale a polveri e sostanze',
    img:'/img/patology/slider/slide6.jpg',
    text:'Un altro pericolo è rappresentato dall’esposizione professionale ad alcune sostanze, come le polveri di legno, di cuoio, di silice, quelle da lavorazione tessile, il nichel e l’amianto.',
    feature:'Proteggere sempre con un abbigliamento idoneo: dove necessario utilizzare maschere, guanti e occhiali di protezione e seguire tutte le regole attinenti'
  },
  {
    title:'Scarsa igiene orale',
    img:'/img/patology/slider/slide7.jpg',
    text:'I tumori del distretto testa-collo possono essere legati ad una cattiva igiene orale. In particolare, alcuni studi hanno evidenziato una correlazione tra flore batteriche legate alla parodintite e il rischio di sviluppare questo tipo di tumore.',
    feature:'Curare la propria igiene orale'
  },
]

export const patologyTab = [
  {
    title:'Sintomi',
    img:'/img/patology/tabs/MSD-01.png',
    id:'sintomi',
    class:'sm',
    path: "/diagnosi/sintomi"
  },
  {
    title:'Specialisti',
    img:'/img/patology/tabs/MSD-02.png',
    id:'specialisti',
    class:'sm',
    path: "/diagnosi/specialisti"
  },
  {
    title:'Esami',
    img:'/img/patology/tabs/MSD-03.png',
    id:'esami',
    class:'sm',
    path: "/diagnosi/esami"
  },
  {
    title:'Diagnosi',
    img:'/img/patology/tabs/MSD-04.png',
    id:'diagnosi',
    class:'sm',
    path: "/diagnosi/diagnosi"
  },
  {
    title:'Prognosi',
    img:'/img/patology/tabs/MSD-05.png',
    id:'prognosi',
    class:'sm',
    path: "/diagnosi/prognosi"
  },
  {
    title:'Trattamenti',
    img:'/img/patology/tabs/MSD-06.png',
    id:'trattamenti',
    class:'sm',
    path: "/diagnosi/trattamenti"
  },
]

export const patologyTabSpecialty = [
  {
    title:'Medico di base e dentista',
    id:'medico-di-base-e-dentista',
    path: '/diagnosi/specialisti/medico-di-base-e-dentista'
  },
  {
    title:'Otorinolaringoiatra o chirurgo maxillo-facciale',
    id:'otorinolaringoiatra',
    path: '/diagnosi/specialisti/otorinolaringoiatra'
  },
  {
    title:'Patologo',
    id:'patologo',
    path: '/diagnosi/specialisti/patologo'
  },
  {
    title:'Oncologo',
    id:'oncologo',
    path: '/diagnosi/specialisti/oncologo'
  },
  {
    title:'Radioterapisti',
    id:'radioterapisti',
    path: '/diagnosi/specialisti/radioterapisti'
  },
  {
    title:'Team multidisciplinare',
    id:'team-multidisciplinare',
    path: '/diagnosi/specialisti/team-multidisciplinare'
  },
]

export const patologyTabExam = [
  {
    title:'Esame obiettivo',
    id:'esame-obiettivo',
    className:'md'
  },
  {
    title:'Endoscopia',
    id:'endoscopia',
    className:'md'
  },
  {
    title:'Esame istologico',
    id:'esame-istologico',
    className:'md'
  },
  {
    title:'Biomarcatori Tumorali',
    id:'biomarcatori-tumorali',
    className:'md'
  },
  {
    title:'Test HPV',
    id:'hpv',
    className:'md'
  },
  {
    title:'Esami radiologici',
    id:'esami-radiologici',
    className:'md'
  },
  {
    title:'Risonanza magnetica',
    id:'risonanza-magnetica',
    className:'sm'
  },
  {
    title:'Tomografia computerizzata',
    id:'tomografia-computerizzata',
    className:'sm'
  },
  {
    title:'PET',
    id:'pet',
    className:'sm'
  },
]

export const diagnoseTabExam = [
  {
    title:'Tumori del cavo orale',
    id:'tumori-del-cavo-orale',
    path: '/diagnosi/diagnosi/tumori-del-cavo-orale'

  },
  {
    title:'Tumori della laringe',
    id:'tumori-della-laringe',
    path: '/diagnosi/diagnosi/tumori-della-laringe'
  },
  {
    title:'Tumori dell’ipofaringe',
    id:'tumori-dell-ipofaringe',
    path: '/diagnosi/diagnosi/tumori-dell-ipofaringe'
  },
  {
    title:'Tumori delle ghiandole salivari',
    id:'tumori-delle-ghiandole-salivari',
    path: '/diagnosi/diagnosi/tumori-delle-ghiandole-salivari'
  }
]

export const treatmentTabObj = [
  {
    title:'Fattori che determinano il tipo di   trattamento',
    id:'fattori-che-determinano-il-tipo-di-trattamento',
    number:'1',
    class:'twolines',
    path: '/diagnosi/trattamenti/fattori-rischio'
  },
  {
    title:'Tipologie di cure',
    id:'tipologie-di-cure',
    number:'2',
    class:'oneline',
    path: '/diagnosi/trattamenti/tipologia-cure'
  },
  {
    title:'Programmi di trattamento',
    id:'programmi-di-trattamento',
    number:'3',
    class:'oneline',
    path:'/diagnosi/trattamenti/programmi-trattamento'
  },
/*     {
    title:'Tipologia di cure',
    id:'chirugia',
    number:'3',
    class:'oneline',
    childrens:[
      {
        title:'Chirurgia',
        id:'chirugia',
      },
      {
        title:'Radioterapia',
        id:'radioterapia',
      },
      {
        title:'Chemioterapia',
        id:'chemioterapia',
      },
      {
        title:'Immunoterapia',
        id:'immunoterapia',
      },
      {
        title:'Terapia biologica o target',
        id:'terapia-biologica-o-target',
      },
    ],
  }, */
  {
    title:'Effetti collaterali dei trattamenti',
    id:'effetti-collaterali',
    number:'4',
    class:'twolines',
    path:'/diagnosi/trattamenti/effetti-collaterali'
  },
  {
    title:'Cosa succede dopo la fine del trattamento?',
    id:'dopo-trattamento',
    number:'5',
    class:'twolines',
    path:'/diagnosi/trattamenti/dopo-trattamento'
  }
]

export default {patologySlideshow,patologyTab,patologyTabSpecialty,patologyTabExam,diagnoseTabExam,treatmentTabObj};
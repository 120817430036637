import React, {useState} from "react";
import { Link } from "react-router-dom";

const Nav = () => {

  const [menuActive, setmenuActive] = useState(false)

  const menuItens = [
    {
      link:'/patologia-prevenzione',
      title:'Patologia \n e prevenzione',
    },
    {
      link:'/diagnosi',
      title:'Diagnosi \ne trattamenti',
    },
    {
      link:'/DisfagiaDisfonia',
      title:'Disfagia \ne Disfonia',
    },
    {
      link:'/quiz-prevenzione',
      title:'Quiz \ndi prevenzione',
    },
    {
      link:'/diritti-paziente-oncologico',
      title:'Diritti Paziente \nOncologico',
    },
    {
      link:'/percorso-nutrizionale',
      title:'Percorso \nNutrizionale',
    }
  ]
  const handleMenu = () => {
    console.log('handlemenu')
    menuActive ? setmenuActive(false) : setmenuActive(true)
  }
  
  return (
  <>
    <header className="sticky-mob">

    <div className="banner-head d-flex flex-center">
      <div className={"menu-area " + (menuActive ? "expanded" : "")}>
        <button className="menu-btn" onClick={() => handleMenu() }>
          {menuActive ? <i className="icon-close"></i> : <i className="icon-menu"></i>}
        </button>
        <Link className="menu-btn" to="/">
            <i className="icon-home"></i>
        </Link>
      </div>
      
      {!menuActive ?
      <>
        <img className="desktop-only" src="img/headers/logos-header.jpg" alt="" />
        <img className="mob-only" src="img/headers/logos-header-m.jpg" alt="" />
      </>
      : ""}
      
    </div>

    {menuActive ? 
      <nav className="menu-mobile">
        <ul>

          {menuItens.map(item =>(
            <li className="break-lines" key={item.link}>
              <Link
                to={item.link}
                onClick={() => setmenuActive(false)}>{item.title}
              </Link>
            </li>
          ))}
        </ul>
        <div className="icon-social">
          <a href="https://www.facebook.com/msdsalute" target="_blank" rel="noopener noreferrer"><i className="col-50 icon-facebook"></i></a>
          <a href="https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA?view_as=subscriber" target="_blank" rel="noopener noreferrer"><i className="col-50 icon-youtube"></i></a>
          <a href="https://www.instagram.com/msd_salute/" target="_blank" rel="noopener noreferrer"><i className="col-50 icon-instagram"></i></a>
          <a href="https://www.linkedin.com/showcase/msd-salute-1/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><i className="col-50 icon-linkedin"></i></a>
        </div>

      </nav>: null}

  </header>
  <header className="sticky">
    <nav className="menu bg-green">
        <ul className="d-flex space-between">
          <li><Link to="/"><i className="icon-home"></i></Link></li>
          {menuItens.map(item =>(
              <li className="break-lines" key={item.link}>
                <Link to={item.link}>{item.title}</Link>
              </li>
          ))}
        </ul>
      </nav>
    </header>
  </>
  )



  };

export default Nav;

import React from "react";

const TumoriDellIpoFaringe = () => (
  <section>
    <div >
      <p>
        Per questa tipologia di tumore la diagnosi avviene tramite{" "}
        <b>un’endoscopia abbinata ad una biopsia</b>. È possibile
        successivamente eseguire la TC che è considerata la metodica di
        scelta per lo studio dell’ipofaringe.
      </p>

      <div className="feature-blue mt-2 sm-font">
        <p>
          Per avere la certezza della presenza di un tumore{" "}
          <b>
            bisogna necessariamente attendere i risultati della biopsia
          </b>
          . Solitamente una massa cervicale viene analizzata con{" "}
          <b>agoaspirato</b>. Le lesioni orali, invece, vengono di norma
          analizzate con una{" "}
          <b>biopsia incisionale o tramite citologia esfoliativa</b>. In
          caso di possibili tumori a livello di rinofaringe, orofaringe
          e laringe si esegue una <b>biopsia endoscopica</b>.
        </p>
      </div>
    </div>


  </section>



);

export default TumoriDellIpoFaringe;


import React from "react";

const TumoriDellaLaringe = () => (
  <section>

    <div >
      <p>
        In caso di <b>tumore alla laringe</b> la diagnosi avviene
        attraverso rinofibrolaringoscopia delle fosse nasali abbinata ad{" "}
        <b>una biopsia ambulatoriale</b>. È possibile successivamente
        eseguire anche una <b>ecografia al collo</b> con eventuale
        agoaspirato, <b>una risonanza magnetica</b> con mezzo di
        contrasto e PET.
      </p>

      <div className="feature-blue mt-2 sm-font">
        <p>
          Per avere la certezza della presenza di un tumore{" "}
          <b>
            bisogna necessariamente attendere i risultati della biopsia
          </b>
          . Solitamente una massa cervicale viene analizzata con{" "}
          <b>agoaspirato</b>. Le lesioni orali, invece, vengono di norma
          analizzate con una{" "}
          <b>biopsia incisionale o tramite citologia esfoliativa</b>. In
          caso di possibili tumori a livello di rinofaringe, orofaringe
          e laringe si esegue una <b>biopsia endoscopica</b>.
        </p>
      </div>
    </div>
  </section>



);

export default TumoriDellaLaringe;


import React from "react";

const socialLinks = [
    {
        title:'facebook',
        id:'face',
        icon:'icon-facebook',
        link:'https://www.facebook.com/msdsalute',
    },
    {
        title:'youtube',
        id:'yout',
        icon:'icon-youtube',
        link:'https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA?view_as=subscriber',
    },
    {
        title:'instagram',
        id:'inst',
        icon:'icon-instagram',
        link:'https://www.instagram.com/msd_salute/',
    },
    {
        title:'linkedin',
        id:'link',
        icon:'icon-linkedin',
        link:'https://www.linkedin.com/showcase/msd-salute-1/?viewAsMember=true',
    },
]
const Social = () => (

  <div className="social-links">
      <ul>
      {socialLinks.map(item=> (
        <li
            className="shadow"
            key={item.id}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <i className={item.icon} title={item.title}></i>
                </a>
            </li>
        ))}
      </ul>
  </div>

);

export default Social;
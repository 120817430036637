import React from "react";
import Nav from "./nav";
import Fotter from "./footer";
import Social from "./social";

const Layout = ({ children }) => (
  <main className="container">
    <Social />
    <Nav />
    {children}
    <Fotter />
  </main>
);

export default Layout;

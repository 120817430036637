const staticUrl = 'https://d2k7kzudasf9t7.cloudfront.net';

export const getPrivacyPolicy = () => {
    return fetch(staticUrl + '/privacy-policy.html').then(response => response.text());
};

export function getFarmacovigilanza() {
    return fetch(staticUrl + '/farmacovigilanza.html').then(response => response.text());
};

export default { getPrivacyPolicy, getFarmacovigilanza };
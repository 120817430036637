import React, { useState } from "react";
import { patologyTabSpecialty } from '../../../../objects/diagnose';

const Specialisti = function () {
    const [SpecialtyTab, setSpecialtyTab] = useState("medico-di-base-e-dentista");
    return (


        <section>
            <h2>Specialisti</h2>
            <div className="row">
                <div className="col-70">
                    <p>Le figure cliniche di riferimento sono:</p>

                    <div className="col-80 my-2">
                        {patologyTabSpecialty.map((item) => (
                            <button
                                className={
                                    "btn " +
                                    (SpecialtyTab === item.id ? "active" : "deactivated")
                                }
                                onClick={() => setSpecialtyTab(item.id)}
                                key={item.id}
                            >
                                {item.title}
                            </button>
                        ))}
                    </div>

                    <div
                        className={
                            SpecialtyTab === "medico-di-base-e-dentista"
                                ? "d-block"
                                : "d-none"
                        }
                    >
                        <p>
                            Sono le prime figure a cui ci si rivolge in caso di sintomi
                            sospetti.
                        </p>
                    </div>
                    <div
                        className={
                            SpecialtyTab === "otorinolaringoiatra" ? "d-block" : "d-none"
                        }
                    >
                        <p>
                            Procede all’esame obiettivo (osservazione diretta) e alla
                            palpazione delle lesioni sospette. Verifica tutte le superfici
                            della mucosa, può richiedere esami diagnostici di
                            approfondimento, eseguire{" "}
                            <b>un esame endoscopico ed effettuare una biopsia.</b> Sono gli
                            specialisti che operano i pazienti candidati ad un intervento
                            chirurgico.
                        </p>

                        <div className="feature-blue mt-2 col-70 box-80">
                            <p>
                                <b>La rinofibrolaringoscopia</b> è un’indagine che utilizza
                                uno strumento ottico che viene inserito attraverso una narice
                                e <b>permette la visione delle cavità nasali</b> (rinofaringe
                                e faringolaringe), consentendo allo specialista di avere una
                                visione completa delle patologie del distretto
                                otorinolaringoiatrico.
                            </p>
                        </div>
                    </div>

                    <div className={SpecialtyTab === "patologo" ? "d-block" : "d-none"}>
                        <p>
                            <b>
                                È lo specialista che analizza in laboratorio il tessuto
                                prelevato.
                            </b>{" "}
                            Il patologo effettuerà la diagnosi di tumore e potrà valutare
                            ulteriori esami di approfondimento sul tessuto prelevato.
                        </p>
                    </div>

                    <div
                        className={
                            SpecialtyTab === "radioterapisti" ? "d-block" : "d-none"
                        }
                    >
                        <p>Sono gli specialisti che effettuano la radioterapia.</p>
                    </div>

                    <div className={SpecialtyTab === "oncologo" ? "d-block" : "d-none"}>
                        <p>
                            Ci si rivolge all’oncologo quando gli esami confermano la
                            presenza di un tumore, ed in particolar modo quando il tumore è
                            localmente avanzato e/o metastatico.
                        </p>
                        <p>
                            L'oncologo, insieme ad altri specialisti, gestisce il
                            trattamento farmacologico, prescrive{" "}
                            <b>
                                il completamento degli accertamenti di stadiazione
                                post-operatoria
                            </b>{" "}
                            e verifica l’indicazione ad una terapia medica.
                        </p>

                        <div className="feature-blue mt-2 col-70 box-80">
                            <p>
                                <b>La stadiazione</b> è un modo per descrivere in maniera
                                schematica quanto è grande un tumore e quanto si è esteso
                                rispetto alla sede originale di sviluppo.
                            </p>
                        </div>
                    </div>

                    <div
                        className={
                            SpecialtyTab === "team-multidisciplinare" ? "d-block" : "d-none"
                        }
                    >
                        <p>
                            È un team di esperti composto{" "}
                            <b>
                                dall’otorinolaringoiatra o dal chirurgo maxillo-facciale,
                                dall’oncologo,
                            </b>
                            quali <b>l’anatomopatologo e il radioterapista</b> i gestiscono
                            l’intervento e definiscono la terapia adatta. Spesso possono
                            essere affiancati anche da{" "}
                            <b>un nutrizionista, un logopedista e uno psicologo</b> per
                            affrontare il percorso di cura con un approccio integrato e
                            completo.
                        </p>
                    </div>
                </div>
                <div className="col-30">
                    <img
                        className="img-scale"
                        src="/img/patology/tabs/specialisti.png"
                        alt=""
                    />
                </div>
            </div>
        </section>

    )
};

export default Specialisti;
export const patologySlideshow = [
    {
      title:'Tabacco',
      img:'/img/patology/slider/slide1.jpg',
      text:'Il rischio dipende dalla quantità di tabacco consumata e dalla durata del suo utilizzo. Si riduce progressivamente, fino ad avvicinarsi a quello dei non fumatori, dopo 15 anni dalla cessazione dell’abitudine.',
      feature:'Non fumare'
    },
    {
      title:'Alcol',
      img:'/img/patology/slider/slide2.jpg',
      text:'Il rischio correlato all’uso di bevande alcoliche aumenta nel tempo ed è proporzionale alla quantità di alcol assunta. Chi beve molto ha un rischio 5 volte maggiore di sviluppare un tumore del cavo orale e 7 volte maggiore di sviluppare un tumore della faringe.',
      feature:'Limitare il consumo di alcolici'
    },
    {
      title:'Papillomavirus umano (HPV)',
      img:'/img/patology/slider/slide3.jpg',
      text:'È stata riscontrata un’infezione da papillomavirus in soggetti con tumore dell’orofaringe, 31% dei casi, meno frequentemente in soggetti con tumori del cavo orale e della laringe (rispettivamente < 10% e 2,4%).',
    },
    {
      title:'Dieta',
      img:'/img/patology/slider/slide4.jpg',
      text:'Un’alimentazione errata, specialmente se povera di vitamina A e di vitamine del gruppo B, può condizionare lo sviluppo della patologia.',
      feature:'Consumare molta frutta e verdura, in particolare alimenti ricchi di carotenoidi, come peperoni, pomodori, carote e zucca'
    },
    {
      title:'Virus di Epstein-Barr',
      img:'/img/patology/slider/slide5.jpg',
      text:'Il virus di Epstein-Barr (EBV), un tipo di herpes virus che si trasmette attraverso la saliva, è associato ai tumori del tratto naso-faringeo.',
    },
    {
      title:'Esposizione professionale a polveri e sostanze',
      img:'/img/patology/slider/slide6.jpg',
      text:'Un altro pericolo è rappresentato dall’esposizione professionale ad alcune sostanze, come le polveri di legno, di cuoio, di silice, quelle da lavorazione tessile, il nichel e l’amianto, responsabili in particolare di alcuni tumori dei seni paranasali.',
      feature:'Proteggere sempre con un abbigliamento idoneo: dove necessario utilizzare maschere, guanti e occhiali di protezione e seguire tutte le regole.'
    },
    {
      title:'Scarsa igiene orale',
      img:'/img/patology/slider/slide7.jpg',
      text:'I tumori del distretto testa-collo possono essere legati ad una cattiva igiene orale. In particolare, alcuni studi hanno evidenziato una correlazione tra flora batterica legata alla parodontite e il rischio di sviluppare questo tipo di tumore.',
      feature:'Curare la propria igiene orale'
    },
  ]

export default {patologySlideshow};
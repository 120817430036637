import React, { useState } from "react";
import Layout from "../components/layout";
import staticContent from "../objects/static";
import MetaTags from "react-meta-tags";
import { JsonLd } from 'react-schemaorg';

const Privacypolicy = function () {

  const [modalActive, setmodalActive] = useState(false);

  const [privacypolicy, setPrivacypolicy] = useState('');

  staticContent.getPrivacyPolicy().then(res => setPrivacypolicy(res));

  return (
    <Layout>
      <JsonLd
        item={{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          "name": "MSD Italia",
          "url": "https://www.testacollo.it/",
          "sameAs": [
            "https://www.linkedin.com/showcase/msd-salute-1/",
            "https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA",
            "https://www.instagram.com/msd_salute/",
            "https://twitter.com/msdsalute",
            "https://www.facebook.com/msdsalute",
            "https://www.msd-italia.it/"
          ],
          "logo": "https://www.testacollo.it/img/headers/logos-header.jpg",
          "image": "https://www.testacollo.it/img/home/home1.jpg",
          "description": "Privacypolicy Pagina di informazione sulla privacypolicy",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Vitorchiano 151",
            "addressRegion": "RM",
            "postalCode": " 00189",
            "addressCountry": "Italy"
          },
          "@graph": [
            {
              "@type": "Privacypolicy",
              "evidenceLevel": "https://schema.org/EvidenceLevelA",
              "evidenceOrigin": "multiple randomized clinical trials",
              "guidelineDate": "2020-12-01T20:00",
              "recommendationStrength": "Strong recommendation",
              "guidelineSubject": [
                {
                  "@type": "Privacypolicy",
                  "name": "Privacy Policy"
                }
              ]
            }
          ]
        }}></JsonLd>
      <MetaTags>
        <title>Tumore Testa Collo | Privacy Policy</title>
        <meta name="description" content="Privacypolicy"></meta>
      </MetaTags>

      <section className="page-section section">
        {modalActive ? (
          <div
            className="img-modal cursor-pointer"
            onClick={() => setmodalActive(false)}
          >
            <img src="/img/patology/head-zoom.png" alt="" />
          </div>
        ) : (
            <>

              <div class="container-fluid">


                <div class="col-12 d-flex mt-6 privacypolicy">
                  <div class="col-lg-6 col-sm-12 my-4">
                    <h1>Privacy Policy</h1>
                    <article dangerouslySetInnerHTML={{ __html: privacypolicy }}></article>
                  </div>
                </div>
              </div>
            </>
          )}
      </section>

    </Layout>
  );
};

export default Privacypolicy;

import React, { useState } from "react";
import Layout from "../components/layout";
import { quizSteps, emptyStateToDb } from "../objects/quiz.js";
import MetaTags from "react-meta-tags";
import { JsonLd } from 'react-schemaorg';

const Quiz = function () {
  const [actualStep, setActualStep] = useState(0);
  const [risky, setRisky] = useState(false);
  const [quiz, setQuiz] = useState(quizSteps);
  const [userId, setuserId] = useState();
  const [dataTosendToDB] = useState(emptyStateToDb);
  const apiUrl = "https://testacollobe.apps.europe.cf.webappsp.com/api/quiz/";

  const updateAnswer = (step, answer) => {
    let newArr = [...quiz];
    newArr[step].userAnswer = answer;
    setQuiz(newArr);
  };
  const resetQuiz = () => {
    setActualStep(1);
    setRisky(false);
    quiz.forEach((item) => {
      item.userAnswer = "";
    });
  };

  const handleClick = (item) => {
    setActualStep(item.id + 1);
    dataTosendToDB[`question_${item.id}`] =
      item.options[item.userAnswer].answer;
    fetch(`${apiUrl}${userId}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataTosendToDB),
    });
    if (actualStep === quiz.length) {
      quiz.forEach((item) => {
        if (item.options[item.userAnswer].changeResult === true) {
          setRisky(true);
        }
      });
    }
  };

  const startQuiz = () => {
    (async () => {
      setActualStep(1);
      const getId = await fetch(apiUrl, { method: "POST" });
      const result = await getId.json();
      setuserId(result.insertId);
    })();
  };

  return (
    <Layout>
      <JsonLd
        item={{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          "name": "MSD Italia",
          "url": "https://www.testacollo.it/",
          "sameAs": [
            "https://www.linkedin.com/showcase/msd-salute-1/",
            "https://www.youtube.com/channel/UC35EEHzhhhhPcfCkADWX9DA",
            "https://www.instagram.com/msd_salute/",
            "https://twitter.com/msdsalute",
            "https://www.facebook.com/msdsalute",
            "https://www.msd-italia.it/"
          ],
          "logo": "https://www.testacollo.it/img/headers/logos-header.jpg",
          "image": "https://www.testacollo.it/img/home/home1.jpg",
          "description": "Scopri attraverso un semplice quiz quali sono i fattori di rischio più comuni che favoriscono l’insorgenza dei tumori che colpiscono naso, bocca e gola.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Via Vitorchiano 151",
            "addressRegion": "RM",
            "postalCode": " 00189",
            "addressCountry": "Italy"
          },
          "@graph": [
            {
              "@type": "MedicalGuidelineRecommendation",
              "evidenceLevel": "https://schema.org/EvidenceLevelA",
              "evidenceOrigin": "multiple randomized clinical trials",
              "guidelineDate": "2020-12-01T20:00",
              "recommendationStrength": "Strong recommendation",
              "guidelineSubject": [
                {
                  "@type": "MedicalCondition",
                  "name": "head and neck cancer"
                }
              ]
            }
          ]
        }}></JsonLd>
      <MetaTags>
        <title>
          Tumore Testa Collo | Quiz Prevenzione e Fattori di Rischio
        </title>
        <meta name="description" content="Scopri attraverso un semplice quiz quali sono i fattori di rischio più comuni che favoriscono l’insorgenza dei tumori che colpiscono naso, bocca e gola."></meta>
        <link rel="canonical" href="https://www.testacollo.it/quiz-prevenzione/"></link>
      </MetaTags>
      <div className="img-header"></div>

      <div className={actualStep === 0 ? "d-block" : "d-none"}>
        <section className="page-section section position-relative mb-2">
          <h1>Il quiz della prevenzione</h1>
          <div className="d-flex flex-center absolute-negative-bottom">
            <button className="btn btn-blue" onClick={() => startQuiz()}>
              INIZIA SUBITO IL QUIZ{" "}
            </button>
          </div>
        </section>

        <section className="page-section-white section">
          <div className="row">
            <div className="col-50">
              <p className="mb-2">
                Rispondi alle domande* e scopri con questo quiz quali sono i
                fattori di rischio più comuni che favoriscono l’insorgenza dei
                tumori che colpiscono naso, bocca e gola.{" "}
                <b>Il quiz non sostituisce il parere di uno specialista,</b> ma
                dà indicazioni su alcune delle principali criticità relative
                alle abitudini e allo stile di vita.
              </p>
              <small>
                *Il quiz ha il solo scopo informativo, nessun dato sarà raccolto
                o visionato da terzi.
              </small>
            </div>
            <div className="col-50">
              <img className="img-scale" src="/img/home/quiz.png" alt="" />
            </div>
          </div>
        </section>
      </div>

      {quiz.map((item, idx) => (
        <div
          className={item.id === actualStep ? "d-block" : "d-none"}
          key={item.id}
        >
          <section className="page-section section position-relative mb-2">
            <div className="row">
              <h1>{item.id}.</h1>
              <h3 className="text-green pl-1">{item.title}</h3>
            </div>
            <div className="row">
              {item.options.map((response, index) => (
                <div
                  className={
                    "col-50 btn btn-radio d-flex vertical-center " +
                    (item.userAnswer === index ? "selected" : "unselected")
                  }
                  key={index}
                  onClick={() => updateAnswer(idx, index)}
                >
                  {response.answer}
                </div>
              ))}
            </div>
            <div className="d-flex absolute-negative-bottom space-between width-calc mx-2">
              <button
                className="btn btn-blue btn-back"
                onClick={() => setActualStep(item.id - 1)}
              ></button>
              <button
                className={
                  "btn mob-btn btn-blue " +
                  (item.userAnswer !== "" ? "d-block" : "d-none")
                }
                onClick={() => handleClick(item)}
              >
                {item.id === quiz.length
                  ? "MOSTRA IL RISULTATO"
                  : "DOMANDA SUCCESSIVA"}
              </button>
            </div>
          </section>
          <section className="page-section-white section">
            <div
              className={
                "row " + (item.userAnswer !== "" ? "d-block" : "d-none")
              }
            >
              <div className="col-20">
                <img className="img-scale-quiz" src={item.img} alt="" />
              </div>
              <div className="col-80 break-lines d-flex vertical-center">
                <p>{item.text}</p>
              </div>
            </div>
          </section>
        </div>
      ))}

      <div
        className={
          actualStep === quiz.length + 1 && !risky ? "d-block" : "d-none"
        }
      >
        <section className="page-section section position-relative mb-2">
          <h1>Il tuo risultato:</h1>
          <div className="d-flex ml-2 absolute-negative-bottom">
            <button
              className="btn btn-blue btn-back"
              onClick={() => setActualStep(10)}
            ></button>
            <button className="btn btn-blue" onClick={() => resetQuiz()}>
              RIPETI IL QUIZ
            </button>
          </div>
        </section>
        <section className="page-section-white section">
          <h3 className="text-green">
            Il tuo stile di vita non sembra presentare particolari fattori di
            rischio!
          </h3>
          <p>
            Continua ad adottare uno stile di vita sano e attivo, segui un
            regime alimentare equilibrato ricco di frutta e verdura e non
            smettere di dare al tuo organismo tutti gli strumenti utili a
            prevenire l'insorgenza della patologia.{" "}
          </p>
          <p>
            <b className="text-green">
              Se vuoi saperne di più sulla prevenzione, vai qui:
            </b>
          </p>
          <div className="d-flex flex-center pt-1">
            <a href="/patologia-prevenzione" className="mob-link">
              <button className="btn btn-blue">FATTORI DI RISCHIO</button>
            </a>
          </div>
        </section>
      </div>

      <div
        className={
          actualStep === quiz.length + 1 && risky ? "d-block" : "d-none"
        }
      >
        <section className="page-section section position-relative mb-2">
          <h1>Il tuo risultato:</h1>
          <div className="d-flex ml-2 absolute-negative-bottom">
            <button
              className="btn btn-blue btn-back"
              onClick={() => setActualStep(10)}
            ></button>
            <button className="btn btn-blue" onClick={() => resetQuiz()}>
              RIPETI IL QUIZ
            </button>
          </div>
        </section>
        <section className="page-section-white section">
          <h3 className="text-green">
            Sulla base delle risposte, potresti presentare alcuni fattori di
            rischio!
          </h3>
          <p>Parlane con il tuo medico. </p>
          <p>
            <b className="text-green">
              Per saperne di più consulta l'area dedicata ai fattori di rischio,
              alla patologia e alla sua diagnosi qui:
            </b>
          </p>
          <div className="d-flex flex-center pt-1">
            <a href="/diagnosi" className="mob-link">
              <button className="btn btn-blue">DIAGNOSI E TRATTAMENTI</button>
            </a>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Quiz;

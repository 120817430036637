import React from "react";

const Prognosi = () => (

  <section>
        <h2>Prognosi</h2>
        <div className="row">
          <div className="col-40">
            <img
              className ="img-scale"
              src="/img/patology/tabs/prognosi.png"
              alt=""
            />
          </div>
          <div className="col-60">
            <p>
              La prognosi di un tumore alla testa e collo è molto variabile e
              può dipendere da:
            </p>
            <ul className="my-2">
              <li className="text-green">
                <b>Le dimensioni del tumore primitivo</b>
              </li>
              <li className="text-green">
                <b>Il sito primario del tumore</b>
              </li>
              <li className="text-green">
                <b>L’eziologia del tumore</b>
              </li>
              <li className="text-green">
                <b>
                  La presenza di metastasi regionali o a distanza ed eventuale
                  mancata radicalità in caso di terapia chirurgica
                </b>
              </li>
            </ul>
            <p>
              Solitamente la prognosi è favorevole quando{" "}
              <b>la diagnosi è stata eseguita precocemente</b> e a seguito di
              trattamenti adeguati e tempestivi. Un tumore al collo e alla testa
              si manifesta localmente con metastasi ai linfonodi regionali
              cervicali; le metastasi a distanza, che possono colpire i polmoni,
              ma anche altri organi, si manifestano successivamente, solo in
              pazienti che si trovano in uno stadio avanzato della malattia. La
              metastasi avanzata riduce notevolmente la possibilità di
              sopravvivenza.
            </p>
            <p>
              <b>La possibilità di guarigione</b> si riduce anche nel caso di
              invasione del tumore delle strutture muscolari, ossee o
              cartilaginee attigue al tumore stesso. Nel caso di invasioni
              perineurali, paralisi ed intorpidimento con segni di stadio
              avanzato di tumore si riducono le possibilità di guarigione.
            </p>
          </div>
        </div>
      </section>


);

export default Prognosi;